import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { PDFDocument, rgb } from "pdf-lib";
import { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import App from "../../App";
import { db, storage } from "../../firebase";
import Footer from "../layout/footer";
import Header from "../layout/header";
import cors from "cors";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const conSubTitle = "Download Hall Ticket";
const conTitle = "Fill The Form Below To Download Hall Ticket.";

const closeButtonStyles = `
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #dd7973;
  color: white;
  font-weight: bold;
  margin: 10px;
`;

const continueButtonStyles = `
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #4681f4;
  color: white;
  font-weight: bold;
  margin: 10px;
`;

const DownloadHTPage = () => {
  async function uploadPhotoDialog(s, atc, student_doc_id) {
    const dialog = document.createElement("div");
    dialog.classList.add("upload-dialog"); // Add a class for easy identification
    dialog.style.position = "fixed";
    dialog.style.top = "0";
    dialog.style.left = "0";
    dialog.style.width = "100%";
    dialog.style.height = "100%";
    dialog.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    dialog.style.display = "flex";
    dialog.style.justifyContent = "center";
    dialog.style.alignItems = "center";

    const content = document.createElement("div");
    content.style.backgroundColor = "#fff";
    content.style.padding = "20px";
    content.style.borderRadius = "8px";

    const title = document.createElement("h4");
    title.innerText = "Upload Photo";

    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleFileChange);

    const closeButton = document.createElement("button");
    closeButton.style = closeButtonStyles;
    closeButton.innerText = "Close";
    closeButton.addEventListener("click", closeDialog);

    const continueButton = document.createElement("button");
    continueButton.style = continueButtonStyles;
    continueButton.innerText = "Continue";
    continueButton.addEventListener("click", () =>
      continueDialog(s, atc, student_doc_id)
    );

    content.appendChild(title);
    content.appendChild(fileInput);
    content.appendChild(closeButton);
    content.appendChild(continueButton);

    dialog.appendChild(content);
    document.body.appendChild(dialog);
  }

  function handleFileChange(event) {
    console.log(file.current);
    const thisFile = event.target.files[0];
    if (thisFile) {
      file.current = thisFile;
      console.log(file.current);
      // photo.current =
      //   "https://firebasestorage.googleapis.com/v0/b/tssr-79f4a.appspot.com/o/student_photo%2FALH%5CKAN%5C130%5C19%2FFAT%5CA1N%5C10238?alt=media&token=f92b4d5d-85ff-45a6-8eea-3a0b68177719";
    }
  }

  function closeDialog() {
    const dialog = document.querySelector(".upload-dialog");
    if (dialog) {
      dialog.parentNode.removeChild(dialog);
      return;
    }
  }

  function continueDialog(s, atc, student_doc_id) {
    const dialog = document.querySelector(".upload-dialog");
    if (dialog) {
      console.log("continue sectin");
      console.log(file.current);
      if (file.current != undefined) {
        const storageRef = ref(
          storage,
          "student_photo/" + atc + "/" + admission_no.current
        ); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file.current);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // const percent = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // ); // update progress
            // getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            //   console.log("getdowndurl");
            //   snapshotF.docs.forEach((f) => {
            //     updateDoc(doc(db, "HT_Data", f.data().id), {
            //       url: url,
            //     }).then((e) => {
            //       console.log("url");
            //       // alert("Request Sent");
            //       window.location.reload(false);
            //     });
            //   });
            // });
          },
          (err) => console.log(err),
          async () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
              await updateDoc(doc(db, "StudentDetails", student_doc_id), {
                photo_url: url,
              });
              photo.current = url;
              admission_no.current = s.data().admission_no;
              name.current = s.data().name;
              course.current = s.data().course;
              study_centre.current = s.data().study_centre;
              exam_centre.current = s.data().exam_centre;
              exam_date.current = s.data().exam_date;
              exam_time.current = s.data().exam_time;
              generatePDF();
            });
          }
        );
        dialog.parentNode.removeChild(dialog);
        return;
      }
    }
  } // implement continue button functionality

  async function handleSubmit() {
    if (admission_no.current != undefined && selectedOptionATC != "") {
      console.log(selectedOptionATC);
      const hallTicketRef = collection(db, "HT_Data");
      const q = query(
        hallTicketRef,
        where("admission_no", "==", admission_no.current)
      );
      const snapshot = await getDocs(q);
      if (snapshot.docs.length > 0) {
        // await uploadPhotoDialog(snapshot.docs[0]);
        const studentDetailsRef = collection(db, "StudentDetails");
        const st_q = query(
          studentDetailsRef,
          where("reg_no", "==", admission_no.current),
          where("study_centre", "==", selectedOptionName)
        );
        const studentSnapshot = await getDocs(st_q);
        if (studentSnapshot.docs.length > 0) {
          const studentData = studentSnapshot.docs[0];
          if (studentData.data()["photo_url"] == "none") {
            uploadPhotoDialog(
              snapshot.docs[0],
              selectedOptionATC,
              studentData.id
            );
          } else {
            photo.current = studentData.data()["photo_url"];
            admission_no.current = snapshot.docs[0].data().admission_no;
            name.current = snapshot.docs[0].data().name;
            course.current = snapshot.docs[0].data().course;
            study_centre.current = snapshot.docs[0].data().study_centre;
            exam_centre.current = snapshot.docs[0].data().exam_centre;
            exam_date.current = snapshot.docs[0].data().exam_date;
            exam_time.current = snapshot.docs[0].data().exam_time;
            generatePDF();
          }
        } else {
          alert("No data found for this student");
        }
      } else {
        alert("The entered admission number does not exist.");
      }
    } else if (selectedOptionATC == "") {
      alert("please select a study centre");
    } else {
      alert("Enter a valid admission number");
    }
  }

  const handleSubmit2 = async (e) => {
    if (admission_no.current != undefined) {
      const Ref = collection(db, "HT_Data");
      const q = query(Ref, where("admission_no", "==", admission_no.current));
      var snapshot = await getDocs(q);
      if (snapshot.docs.length > 0) {
        snapshot.docs.forEach((s) => {
          if (s.data().url == "none") {
            alert(
              "Please upload a photo and register before downloading hall ticket!!"
            );
          } else {
            admission_no.current = s.data().admission_no;
            name.current = s.data().name;
            course.current = s.data().course;
            study_centre.current = s.data().study_centre;
            exam_centre.current = s.data().exam_centre;
            exam_date.current = s.data().exam_date;
            exam_time.current = s.data().exam_time;
            photo.current = s.data().url;
            generatePDF();
          }
        });
      } else {
        alert("Invalid or Unprocessed Request");
      }
    } else {
      alert("Please Enter Admission No");
    }
  };

  const resettoempty = () => {
    admission_no.current = "";
    name.current = "";
    course.current = "";
    photo.current = "";
    exam_centre.current = "";
    exam_time.current = "";
  };

  async function generatePDF() {
    console.log(photo.current + ".jpg");
    // Create a new document and add a new page
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();

    let img = await fetch("/assets/images/pdf/pdf_temp.jpg").then((res) =>
      res.arrayBuffer()
    );
    img = await pdfDoc.embedJpg(img);

    let photo_url = await fetch(photo.current + ".jpg").then((res) =>
      res.arrayBuffer()
    );
    try {
      photo_url = await pdfDoc.embedJpg(photo_url);
    } catch (e) {
      photo_url = await pdfDoc.embedPng(photo_url);
    }

    const { width, height } = img.scale(0.1);
    page.drawImage(img, {
      x: 0,
      y: 0,
      height: page.getHeight(),
      width: page.getWidth(),
    });

    // Get the width and height of the page

    // Draw a string of text toward the top of the page

    page.drawImage(photo_url, {
      x: 460,
      y: 530,
      height: 120,
      width: 100,
    });

    //admission no
    page.drawText(admission_no.current, {
      x: 195,
      y: 660,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //name
    page.drawText(name.current, {
      x: 195,
      y: 630,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //course
    page.drawText(course.current, {
      x: 195,
      y: 600,
      size: 10,
      color: rgb(0, 0, 0),
    });

    //study centre
    page.drawText(study_centre.current, {
      x: 195,
      y: 568,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //exam centre
    page.drawText(exam_centre.current, {
      x: 195,
      y: 535,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //date
    page.drawText(exam_date.current, {
      x: 195,
      y: 507,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //time
    page.drawText(exam_time.current, {
      x: 195,
      y: 475,
      size: 12,
      color: rgb(0, 0, 0),
    });

    // Write the PDF to a file
    const pdfData = await pdfDoc.save();
    const blob = new Blob([pdfData], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "hall_ticket.pdf";
    document.body.appendChild(link);
    link.click();
  }

  const admission_no = useRef();
  const name = useRef();
  const course = useRef();
  const study_centre = useRef();
  const exam_centre = useRef();
  const exam_date = useRef();
  const exam_time = useRef();
  const photo = useRef();

  const isMetaDataFetched = useRef(false);
  const file = useRef();

  ///////////////////////////////////
  //   generatePDF();
  ///////////////////////////////////////

  const [firstDocument, setFirstDocument] = useState(null);

  useEffect(() => {
    // Fetch the first document in the collection
    const fetchFirstDocument = async () => {
      if (isMetaDataFetched.current == false) {
        try {
          const collectionRef = collection(db, "MetaInfo");
          const q = query(collectionRef, limit(1));
          var snapshot = await getDocs(q);
          const firstDoc = snapshot.docs[0].data();
          setFirstDocument(firstDoc);

          options.current = firstDoc.franchises.map((item) => {
            // const [label, value] = item.split("♪");
            const [label, value] = item.split("+=+");
            return { label, value };
          });

          options.current = [...options.current].filter(
            (item) => !(item.label === "ADMIN" && item.value === "ADMIN")
          );

          options.current = [...options.current].sort((a, b) =>
            a.label.localeCompare(b.label)
          );

          console.log(options.current);
        } catch (error) {
          console.error("Error fetching the first document:", error);
        } finally {
          isMetaDataFetched.current = true;
        }
      }
    };

    fetchFirstDocument();
  }, []);

  // State to keep track of the selected option
  const [selectedOptionATC, setSelectedOption] = useState("");
  const [selectedOptionName, setSelectedOptionName] = useState("");
  const options = useRef([]);

  // Event handler to update the selected option
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
    const label = options.current.find(
      (option) => option.value === event.target.value
    ).label;
    setSelectedOptionName(label);
  };

  const centeredDivStyle = {
    margin: "0 auto",
    textAlign: "center",
    maxWidth: "400px",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  };

  return (
    <Fragment>
      <Header />
      <div className="map-address-section padding-tb section-bg"></div>
      <div className="contact-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            <form>
              <div className="form-group">
                <center>
                  {" "}
                  <input
                    type="text"
                    name="admission_no"
                    style={{ width: 500, backgroundColor: "lightgrey" }}
                    onChange={(e) => (admission_no.current = e.target.value)}
                    placeholder="Admission Number *"
                  />
                </center>
              </div>
              <div style={{ height: 50 }}></div>
              <div className="form-group" style={centeredDivStyle}>
                <center>
                  {" "}
                  <label htmlFor="dropdown">Select an option:</label>
                  <select
                    id="dropdown"
                    value={selectedOptionATC}
                    onChange={handleSelectChange}
                  >
                    <option value="" label="-- Select --">
                      -- Select --
                    </option>
                    {options.current.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.label}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {/* {selectedOptionATC && <p>You selected: {selectedOptionATC}</p>} */}
                </center>
              </div>
              <div style={{ height: 50 }}></div>
              <div className="form-group w-100 text-center">
                <input
                  type={"button"}
                  value={"Submit"}
                  style={{ width: 200, backgroundColor: "lightgreen" }}
                  // onClick={handleSubmit2}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default DownloadHTPage;
