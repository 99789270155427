import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useRef } from "react";
import { Component, Fragment, useState } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { db } from "../firebase";
//import Achievement from "../component/section/achievement";
//import Student from "../component/section/student";

//const subTitle = "TSSR COUNCIL";
const title = "FIND RESULT";
const btnText = "Find Result";

//onst btnText = "Browse All Categories";

let categoryItemList = [
  // {
  //     imgUrl: 'assets/images/category/icon/15.jpg',
  //     imgAlt: 'category',
  //     title: 'Build Your Brand',
  //     desc: 'Like graphic design, business anaytics coding and much more',
  // },
  // {
  //     imgUrl: 'assets/images/category/icon/16.jpg',
  //     imgAlt: 'category',
  //     title: 'Plan Your Course',
  //     desc: 'Like graphic design, business anaytics coding and much more',
  // },
  {
    imgUrl: "assets/images/category/icon/search.png",
    imgAlt: "category",
    title: "Find Result",
    desc: "Enter Registration Number of the candidate and press Find result button to get result.",
  },
];

const TsccResult = () => {
  const [id, setRegId] = useState("");
  const [height, setHeight] = useState(0);
  const [pass, setPass] = useState("");

  const clear = (e) => {
    document.getElementById("reg_no").innerHTML = "";
    document.getElementById("duration").innerHTML = "";
    document.getElementById("course").innerHTML = "";
    document.getElementById("name").innerHTML = "";
    document.getElementById("study_centre").innerHTML = "";
    document.getElementById("exam_centre").innerHTML = "";
    document.getElementById("date").innerHTML = "";
    document.getElementById("result").innerHTML = "";
    document.getElementById("grade").innerHTML = "";
  };

  // const handleSubmit2 = (e) => {
  //   // e.prevendDefault();

  //   // onSnapshot(doc(db, "TssrData", reg_id), (doc) => {
  //   //   rdata = doc.data();
  //   //   //#console.log(rdata);
  //   // });

  //   const q = query(collection(db, "Results"), where("reg_no", "==", id));
  //   getDocs(q)
  //     .then((e) => {
  //       e.docs.forEach((d) => {
  //         bdata = d.data();
  //       });
  //     })
  //     .then(() => {
  //       document.getElementById("reg_no").innerHTML = bdata.reg_no;
  //       document.getElementById("duration").innerHTML = bdata.duration;
  //       document.getElementById("course").innerHTML = bdata.course;
  //       document.getElementById("name").innerHTML = bdata.name;
  //       document.getElementById("study_centre").innerHTML = bdata.study_centre;
  //       document.getElementById("exam_centre").innerHTML = bdata.exam_centre;
  //       document.getElementById("date").innerHTML = bdata.date;
  //       document.getElementById("result").innerHTML = bdata.result;
  //       document.getElementById("grade").innerHTML = bdata.grade;
  //     });
  // };

  const handleSubmit = async(e) => {
    const Ref = collection(db, "Results");
    const q = query(Ref, where("reg_no", "==", id));
    var snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      setHeight(200);
      snapshot.docs.forEach((doc) => {
        if (doc.data().result.toLowerCase().replace(/\s/g, "") == "pass") {
          setPass("/assets/images/cursor/passed.png");
        } else {
          setPass("/assets/images/cursor/failed.png");
        }
        //#console.log(doc.data());
        document.getElementById("reg_no").innerHTML = doc.data().reg_no;
        document.getElementById("duration").innerHTML = doc.data().duration;
        document.getElementById("course").innerHTML = doc.data().course;
        document.getElementById("name").innerHTML = doc.data().name;
        document.getElementById("study_centre").innerHTML =
          doc.data().study_centre;
        document.getElementById("exam_centre").innerHTML =
          doc.data().exam_centre;
        document.getElementById("date").innerHTML = doc.data().exam_date;
        document.getElementById("result").innerHTML = doc.data().result;
        document.getElementById("grade").innerHTML = doc.data().grade;
      });
    } else {
      setHeight(0);
      alert("Invalid or Non-Verified ATC Code");
      clear();
    }
  };

  const style = {
    fontSize: 20,
    fontWeight: "bold",
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"TSSR COUNCIL"} curPage={"instructor"} />

      <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
        <div className="container">
          <div className="section-header text-center">
            {/* <span className="subtitle">{subTitle}</span> */}
            <h2 className="title">{title}</h2>
          </div>

          <form className="account-form">
            <div className="form-group">
              <input
                value={id}
                onChange={(e) => setRegId(e.target.value)}
                type="text"
                name="name"
                placeholder="Certificate Registration No *"
              />
            </div>

            <div className="form-group text-center">
              <input type={"button"} onClick={handleSubmit} value="submit" />
            </div>

            {/* <div className="form-group">
                                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                   
                                </div>
                            </div> */}
            {/* <div className="form-group text-center">
                                <button className="d-block lab-btn"><span>{btnText}</span></button>
                            </div> */}
          </form>

          {/* <div className="text-center mt-5">
                            <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                        </div> */}

          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="form-group" style={{ width: 200 }}>
              <label className="admin-page-label">Register No : </label>
              <p id="reg_no" style={style}></p>
              <label className="admin-page-label">Name : </label>
              <p id="name" style={style}></p>
              <label className="admin-page-label">Course : </label>
              <p id="course" style={style}></p>
              <label className="admin-page-label">Study Centre : </label>{" "}
              <p id="study_centre" style={style}></p>
              <label className="admin-page-label">Exam Centre : </label>{" "}
              <p id="exam_centre" style={style}></p>
              <label className="admin-page-label">Duration : </label>
              <p id="duration" style={style}></p>
              <label className="admin-page-label">Date : </label>{" "}
              <p id="date" style={style}></p>
              <label className="admin-page-label">Result : </label>
              <p id="result" style={style}></p>
              <label className="admin-page-label">Grade : </label>{" "}
              <p id="grade" style={style}></p>
            </div>
            <img src={pass} style={{ height: height, width: height }} />
          </div>
          <div style={{ height: 100 }}></div>
        </div>
      </div>
      {/* <Student />
            <Achievement /> */}
      <div style={{ height: 100 }}></div>
      <Footer />
    </Fragment>
  );
};

export default TsccResult;
