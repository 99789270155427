import React, { Fragment, Component } from "react";
import Footer from "../layout/footer";
import { db, tsscCol, tssrCol } from "../../firebase";
import { addDoc, deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { GoogleAuthProvider } from "firebase/auth";
import * as XLSX from "xlsx";
import { SheetJSFT } from "../../file_read/types";
import { make_cols } from "../../file_read/MakeColumns";
import Login from "../../page/login";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import { useRef } from "react";
import Header from "../layout/admin_header";


const conSubTitle = "TSCC";
const conTitle = "Admin Data Upload";
const btnText = "submit";

const TsscAdminUploadPage = () => {
  // const [reg_no, setRegNo] = useState("");
  // const [name, setName] = useState("");
  // const [course, setCourse] = useState("");
  // const [study_centre, setStudyCentre] = useState("");
  // const [exam_centre, setExamCentre] = useState("");
  // const [date, setDate] = useState("");
  // const [result, setResult] = useState("");
  // const [grade, setGrade] = useState("");

  // const duration = useRef();
  const reg_no = useRef();
  const name = useRef();
  const skill = useRef();
  const skill_test_centre = useRef();
  const date = useRef();

  const handleSubmit = (e) => {
    addDoc(tsscCol, {})
      .then((e) => {
        setDoc(doc(db, "TSSC_Data", e.id), {
          id: e.id,
          // duration:duration.current,
          reg_no:reg_no.current,
          name: name.current,
          skill: skill.current,
          skill_test_centre: skill_test_centre.current,
          date: date.current,
        }).then(() => {
          alert("Data Uploaded");
          document.getElementById("tssc-form").reset();
          resettoempty()
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const resettoempty=()=>{
    reg_no.current=""
    // duration.current=""
    name.current=""
    skill.current=""
    skill_test_centre.current=""
    date.current=""
  }

  // const test = (e) => {
  //   items.forEach(function(obj){
  //     addDoc(tssrCol,{
  //       reg_no:obj.reg_id,
  //       name:obj.name,
  //       course:obj.course,
  //       study_centre:obj.study_centre,
  //       exam_centre:obj.exam_centre,
  //       date:obj.date,
  //       result:obj.result,
  //       grade:obj.grade
  //     }).then(function(docRef){
  //       //#console.log("ID : ",docRef.id)
  //     }).catch(function(error){
  //       //#console.log(error)
  //     });
  //   });
  // }

  // const readUploadFile = (d)=>{
  //   //#console.log('pressed')
  //   const reader = new FileReader();
  //     reader.onload=(e)=>{
  //       const data = e.target.result;
  //           const workbook = x.read(data, { type: "array" });
  //           const sheetName = workbook.SheetNames[0];
  //           const worksheet = workbook.Sheets[sheetName];
  //           const json = x.utils.sheet_to_json(worksheet);
  //           //#console.log(json);
  //     }
  // }


  const [ver, setState] = useState(false);
  onAuthStateChanged(auth, (user) => {
    if (user) setState(true);
    // else setState(false);
  });

 

  if (ver == true)
    return (
      <Fragment>
        <Header/>
        <div className="contact-section padding-tb">
          <div className="container">
            <div className="section-header text-center">
              <span className="subtitle">{conSubTitle}</span>
              <h2 className="title">{conTitle}</h2>
            </div>
            <div className="section-wrapper">
              <form className="contact-form" id="tssc-form">
                {/* ///////////////////////// */}
                {/* Register Number */}
                <div className="form-group">
                  <label className="admin-page-label">Register Number</label>
                  <input
                    id="reg_no"
                    value={reg_no.current}
                    onChange={(e) => (reg_no.current = e.target.value)}
                    type="text"
                    name="reg_no"
                    placeholder="Register Number"
                  />
                </div>
                {/* name */}
                <div className="form-group">
                  <label className="admin-page-label">Name</label>
                  <input
                    id="name"
                    value={name.current}
                    onChange={(e) => (name.current = e.target.value)}
                    type="text"
                    name="name"
                    placeholder="Your Name *"
                  />
                </div>
                {/* Skill */}
                <div className="form-group">
                  <label className="admin-page-label">Skill</label>
                  <input
                    id="skill"
                    value={skill.current}
                    onChange={(e) => (skill.current = e.target.value)}
                    type="text"
                    name="skill"
                    placeholder="Skill"
                  />
                </div>
                {/* Skill Test Centre */}
                <div className="form-group">
                  <label className="admin-page-label">
                    Skill Test Centre
                  </label>
                  <input
                    id="skill_test_centre"
                    value={skill_test_centre.current}
                    onChange={(e) => (skill_test_centre.current = e.target.value)}
                    type="text"
                    name="skill_test_centre"
                    placeholder="Skill Test Centre"
                  />
                </div>
                {/* Duration */}
                {/* <div className="form-group">
                  <label className="admin-page-label">Duration</label>
                  <input
                    id="duration"
                    value={duration.current}
                    onChange={(e) => (duration.current = e.target.value)}
                    type="text"
                    name="duration"
                    placeholder="Duration"
                  />
                </div> */}
                {/* Date of examination */}
                <div className="form-group">
                  <label className="admin-page-label">
                    Date of Examination
                  </label>
                  <input
                    id="date"
                    value={date.current}
                    onChange={(e) => (date.current = e.target.value)}
                    style={{ color: "#000000" }}
                    type="date"
                    name="examination date"
                    placeholder="Date of examination"
                  />
                </div>
                {/* submit button  */}
                <div className=" w-100 text-center">
                  {/* <button className="lab-btn" onSubmit={handleSubmit}>
                  <span>{btnText}</span>
                </button> */}
                  <input
                    type={"button"}
                    value={"submit"}
                    onClick={handleSubmit}
                    className="file-upload-btn"
                  />
                </div>
                {/* ///////////////////////////////// */}
              </form>
              {/* <input type={'button'} value={'test'} onClick={test}/> */}
              <br />
              <br />
              <label>Certificate Id : </label>
              <h4 id="docRef"></h4>
              <center>
                <h2>OR</h2>
              </center>
              <h5 htmlFor="upload">Upload File</h5>
              <ExcelReader />
            </div>
          </div>
        </div>
      </Fragment>
    );
  else return <Login />;
};

export default TsscAdminUploadPage;

class ExcelReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: [],
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  }

  handleFile() {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
        //#console.log(JSON.stringify(this.state.data, null, 2));
        this.bulkUpload(this.state.data);
      });
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }

 

  
  bulkUpload = (e) => {
    e.forEach(function (obj) {
      addDoc(tsscCol, {})
      .then((e) => {
        setDoc(doc(db, "TSSC_Data", e.id), {
          id: e.id,
          reg_no: obj.reg_no,
          // duration:obj.duration,
          skill: obj.skill,
          name:obj.name,
          skill_test_centre: obj.skill_test_centre,
          date: obj.date,
        }).then(() => {
          //#console.log(e.id)
        });
      })
      .catch((error) => {
        //#console.log(error.message);
      });
    });
    alert('Data Uploaded (Group)')
    // document.getElementById("file").reset();
  };


  

  render() {
    return (
      <div className="contact-form">
        <label htmlFor="file">Upload an excel to Process Triggers</label>
        <br />
        <input
          type="file"
          className="form-control"
          id="file"
          accept={SheetJSFT}
          onChange={this.handleChange}
        />
        <br />
        <input
          type="button"
          className="file-upload-btn"
          value="Confirm and Submit"
          onClick={this.handleFile}
        />
      </div>
    );
  }
}
