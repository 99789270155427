
import Rating from "../sidebar/rating";
import { Link, NavLink } from "react-router-dom";

const title = "Our Features";
const subTitle = "Why Choose Us";


const studentList = [
    {
        imgUrl: 'assets/images/feedback/student/admission.jpg',
        imgAlt: 'student rajibraj91 rajibraj',
        name: 'Specialized Standard Syllabus',
        //degi: 'UX designer',
        desc: 'Technical Study and Research (TSSR) Council has specialized standard syllabus for all our courses.All our 1400+ courses syllabus are made by gving focus on job students will get after the course completion.',
    },
    {
        imgUrl: 'assets/images/feedback/student/ap1.jpg',
        imgAlt: 'student rajibraj91 rajibraj',
        name: 'Our Experience',
        degi: '7 years of experience',
        desc: 'Our agenda for sustainable learning is based on two principles called partnership as well as trustworthy learning practices.Since the scope of TSSR Council is to effectively implement the plans of the Government of India in an educational scenario along with robust skill development for students from all over India advancing understanding across diverse cultural margins.',
    },
]


const Student = () => {
    return (
        <div className="student-feedbak-section padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                <h1 className="title">{title}</h1>
                    <span className="subtitle">{subTitle}</span>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col">
                            <div className="sf-left">
                                <div className="sfl-thumb">
                                    <img src="/assets/images/gallery/2.jpeg" alt="student feedback" />
                                    <a href="/assets/images/gallery/video.mp4"  className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                    <img src={`${val.imgUrl}`} alt={val.imgAlt} style={{height:150,width:150}}/>
                                                </div>
                                                <div className="sftl-content">
                                                    <Link to="/team-single"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div>
                                            </div>
                                            <div className="sft-right">
                                                <Rating />
                                            </div>
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Student;