import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const subTitle = "Don’t Miss the Day";
const title = "The People Says About Us";

const clientList = [
  {
    desc: "Registered under Government of Kerala, department of registration as a non profit making educational trust.",
  },
  {
    desc: "Registered under Government of India as a Non Profit Non Government Organization (NGO).",
  },
  {
    desc: "Registered under Government of India as MSME in educational sector. (Council registred under MSME Department).",
  },
  {
    desc: "Registered under the Indian Intellectual Property Rights office under Indian Trademarks Act 1999.",
  },
  {
    desc: "Quality Management System is certified as per the ISO 9001: 2015 (International Organization for Standardization)by the Absolute Quality Certification Middle East FZE, Ajman.",
  },
  {
    desc: "Quality Management System is Accredited by the International Accreditation Service , Brea, California, United States of America - A member of Asia Pacific Accreditation Cooperation.",
  },
  {
    desc: "International Accreditation Forum accredited QMS Certification.",
  },
  {
    desc: "Learning Management System is certified as per International Organization for Standardization standards  ISO 29990:2010 from Opastaja Quality Control USA with UBAS accreditation.",
  },
  {
    desc: "Certified by the ISO 21001:2018 for Management System for Educational Organizations (EOMS) from Opastaja Quality Control USA with UBAS accreditation.",
  },
  {
    desc: "Registered under the Department of Income Tax, Central Board of Direct Taxes.",
  },
  {
    desc: "Registered under the Goods and Service tax of Government of India.",
  },
  {
    desc: "Registered under the C V C, Government of India.",
  },
  {
    desc: "Certificate is registered under the Indian Intellectual Property Rights office under the Indian Copyright act 1957.",
  },
  {
    desc: "Practically proven and certified managmenet system for the assessment of students, assessors and institutions.",
  },
  {
    desc: "Professionally designed and council approved syllubus and assessment system.",
  },
];

const AccreditationText = () => {
  return (
    <div className="clients-section padding-tb">
      <div className="container">
        {/* <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div> */}
        <div className="section-wrapper">
          <div className="clients-slider overflow-hidden">
            <div className="swiper-wrapper">
              <Swiper
                spaceBetween={20}
                slidesPerView={1}
                loop={"true"}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  0: {
                    autoplay:true,
                  slidesPerGroupAuto:1,
                    width: 0,
                    slidesPerView: 1,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 2.8,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 4,
                    spaceBetween: 40
                  }
                }}
              >
                {clientList.map((val, i) => (
                  <SwiperSlide key={i}>
                    <div className="client-item">
                      <div className="client-inner">
                        <div className="client-thumb">
                          {/* <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /> */}
                          <h3 style={{ fontSize: 50, paddingTop: 15 ,color:'GrayText'}}>
                            <center>{i + 1}</center>
                          </h3>
                        </div>
                        <div className="client-content">
                          <p style={{color:'#000000'}}>{val.desc}</p>
                          {/* <div className="client-info">
                                                        <h6 className="client-name">{val.name}</h6>
                                                        <span className="client-degi">{val.degi}</span>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccreditationText;
