import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
//import Pagination from "../component/sidebar/pagination";


// const blogList = [
// //     {
// //         // imgUrl: '/assets/images/franchise/01.jpg',
// //         // imgAlt: 'Blog Thumb',
// //         title: 'Tamil Nadu',
// //         //desc: "ISBEE eduHUB No.7/3 Pallam Street Secretariat Colony Ambattur Chennai South India - 600053",
// //          link :'/assets/images/franchise/kerala.jpeg',
      
// //     },
// //     {
// //         imgUrl: '/assets/images/franchise/job.jpg',
// //         imgAlt: 'Kerala',
// //         title: 'Kerala',
// //         //desc: 'Central Administrative Office, TSSR COUNCIL, Ellokara, Kozhikkode, Kerala, India - 673586',
// //         link: '',
      
// //     },
// //     {
// //         imgUrl: '/assets/images/blog/03.jpg',
// //         imgAlt: 'New Delhi',
// //         title: 'Karnataka',
// //         //desc: 'Registered Office B - 38 UGF, Vishal Enclave, Rajouri Garden, New Delhi - 110027',
// //         link:'/coming_soon',
    
// //     },

    
   
// // // ]
// const table= {
//     padding: 20,
//     borderRadius: 0,
// };

// const theaddark= {

//     color: "#fff",
//     backgroundColor: "#212529",
//     borderColor: "#32383e",

// };
// const td = {
//     fontSize: "30px",
    

// };



//const btnText = "Browse All Categories";

let categoryItemList = [
    {
        imgUrl: '/assets/images/icon/01.png',
        imgAlt: 'category',
        title: 'Tamil Nadu',
        desc: 'Like graphic design, business anaytics coding and much more',
       link : "/assets/images/franchise/kerala.jpeg"
    },
    {
        imgUrl: '/assets/images/icon/01.png',
     
        imgAlt: 'category',
        title: 'Karnataka',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/#/coming_soon'
    },

    {
        imgUrl: '/assets/images/icon/01.png',
    
        imgAlt: 'category',
        title: 'Maharashtra',
        desc: 'Like graphic design, business anaytics coding and much more',
        link: '/#/coming_soon'
    },
    
    {   
        imgUrl: '/assets/images/icon/01.png',
     
        imgAlt: 'category',
        title: 'Goa',
        desc: 'Like graphic design, business anaytics coding and much more',
        link: '/#/coming_soon'
    },
    {
        imgUrl: '/assets/images/icon/01.png',
        imgAlt: 'category',
        title: 'Uttar Pradesh',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/#/coming_soon'
    },
    {
        imgUrl: '/assets/images/icon/01.png',
        imgAlt: 'category',
        title: 'Bihar',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/#/coming_soon'
    },
    {
        imgUrl: '/assets/images/icon/01.png',
        imgAlt: 'category',
        title: 'New Delhi',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/#/coming_soon'
    },
    {
        imgUrl: '/assets/images/icon/01.png',
        imgAlt: 'category',
        title: 'Hyderabad',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/#/coming_soon'
    },
   
]


const StateFranchise = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'State Franchise'} curPage={'Blog'} />
          


            <div className="category-section padding-tb section-bg style-3">
                <div className="container">
                    {/* <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div> */}
                    <div className="section-wrapper">
                        <div className="row g-1 justify-content-center row-cols-lg-4 row-cols-sm-3 row-cols-2">
                            
                            {categoryItemList.map((val, i) => (
                                <div className="col" key={i}>

                                    <div className="category-item text-center">
                                        <div className="category-inner">
                                            <div className="category-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="category-content">
                                                <a href={val.link}> <h4>{val.title}</h4></a>
                                              
                                                  {/* <Link to="assets/images/pdf/hall ticket.pdf"><h4>{val.title}</h4></Link> 
                                                  <p>{val.desc}</p>  */}
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div className="text-center mt-5">
                            <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                        </div> */}
                    </div>
                </div>
            </div>






{/* 
            <table class="table" style={table}>
  <thead class="thead-dark" style={theaddark}> 
    <tr>
      <th scope="col">#</th>
      <th scope="col"> State Franchise</th>
      
      <th scope="col">Details</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td style={td}>Tamil Nadu</td>
     <button> <td>Click here</td></button> 
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
    </tr>
  </tbody>
</table> */}

{/* <table class="table">
  <thead class="thead-light">
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table> */}
            <Footer />
        </Fragment>
    );
}


export default StateFranchise;