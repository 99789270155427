import { Component, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import Achievement from "../component/section/achievement";
// import Student from "../component/section/student";

const subTitle = "TSSR";
const title = "DOWNLOAD SECTION";
//const btnText = "Browse All Categories";

let categoryItemList = [
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'Franchise Application Form',
        desc: 'Like graphic design, business anaytics coding and much more',
       link : "/assets/images/pdf/Franchisee Application form format- TSSR.pdf"
    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'Franchise Terms and Condition',
        desc: 'Like graphic design, business anaytics coding and much more',
        link: '/assets/images/pdf/FRANCHASE TERMS & CONDETION.pdf'
    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'Franchise Proposal',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/assets/images/pdf/Franchisee Application form format- TSSR.pdf'
    },
    {   
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'Course List',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'assets/images/pdf/TSSR COURSES.pdf'

    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'Franchise Request Format',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/assets/images/pdf/FRANCHISE REQUEST.pdf'
    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'TSSR Checklist',
        desc: 'Like graphic design, business anaytics coding and much more',
        link: '/assets/images/pdf/TSSR  check  list-.pdf'
    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'Verification Format',
        desc: 'Like graphic design, business anaytics coding and much more',
        link: '/assets/images/pdf/Verification report  format-.pdf'
    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'Brochure',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/assets/images/pdf/TSSR COURSES.pdf'
    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'TSSR Circular',
        desc: 'Like graphic design, business anaytics coding and much more',
        link: '/assets/images/pdf/CIRCULAR TSSR.pdf'
    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'Franchise Renewal Form',
        desc: 'Like graphic design, business anaytics coding and much more',
        link:'/assets/images/pdf/FRANCHISE RENEWAL FORM.pdf'
    },
    {
        imgUrl: '/assets/images/category/icon/download.png',
        imgAlt: 'category',
        title: 'TSSR Refund Policy',
        desc: 'Like graphic design, business anaytics coding and much more',
        link: '/assets/images/pdf/REFUND-POLICY TSSR.pdf'
    },

]



const InstructorPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Downloads'} curPage={'instructor'} />
            <div className="category-section padding-tb section-bg style-3">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-1 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-2">
                            
                            {categoryItemList.map((val, i) => (
                                <div className="col" key={i}>

                                    <div className="category-item text-center">
                                        <div className="category-inner">
                                            <div className="category-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="category-content">
                                                <a href={val.link}> <h4>{val.title}</h4></a>
                                              
                                                  {/* <Link to="assets/images/pdf/hall ticket.pdf"><h4>{val.title}</h4></Link> 
                                                  <p>{val.desc}</p>  */}
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div className="text-center mt-5">
                            <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <Student />
            <Achievement /> */}
            <Footer />
        </Fragment>
    );
}
 
export default InstructorPage;