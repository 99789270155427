import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { PDFDocument, rgb } from "pdf-lib";
import { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import App from "../../App";
import { db, storage } from "../../firebase";
import Footer from "../layout/footer";
import Header from "../layout/header";
import cors from "cors";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const conSubTitle = "Result";
const conTitle = "Download Result.";

const DownloadMarksheetPage = () => {
  async function handleSubmit() {
    const q = query(
      collection(db, "Marksheet"),
      where("regNo", "==", regNo.current.toString())
    );
    const snapshot = await getDocs(q);

    if (snapshot.docs.length > 0) {
      const doc = snapshot.docs[0];

      const dateStr = doc.data().dateOfExam;
      const dateObj = new Date(dateStr);
      const formattedDate = `${dateObj.getDate()}/${
        dateObj.getMonth() + 1
      }/${dateObj.getFullYear()}`;

      regNo.current = doc.data().regNo;
      name.current = doc.data().name;
      course.current = doc.data().course;
      duration.current = doc.data().duration;
      grade.current = doc.data().grade;
      examDate.current = formattedDate;
      childPsychology.current = doc.data().childPsychology;
      healthAndNutrition.current = doc.data().healthAndNutrition;
      teachingMethodology.current = doc.data().teachingMethodology;
      preSchoolManagement.current = doc.data().preSchoolManagement;
      communicativeEnglish.current = doc.data().communicativeEnglish;
      malayalam.current = doc.data().malayalam;
      teachingPractice.current = doc.data().teachingPractice;
      commisionAndViva.current = doc.data().commisionAndViva;
      campTourSocialWork.current = doc.data().campTourSocialWork;
      craftCollection.current = doc.data().craftCollection;
      studyCentre.current = doc.data().studyCentre;
      examCentre.current = doc.data().examCentre;
      remarks.current = doc.data().remarks;

      await generatePDF();
    }
  }

  const resettoempty = () => {
    regNo.current = "";
    name.current = "";
    course.current = "";
    studyCentre.current = "";
    examCentre.current = "";
    duration.current = "";
    grade.current = "";
    examDate.current = "";
    childPsychology.current = "";
    healthAndNutrition.current = "";
    teachingMethodology.current = "";
    preSchoolManagement.current = "";
    communicativeEnglish.current = "";
    malayalam.current = "";
    teachingPractice.current = "";
    commisionAndViva.current = "";
    campTourSocialWork.current = "";
    craftCollection.current = "";
    remarks.current = "";
  };

  const regNo = useRef();
  const name = useRef();
  const course = useRef();
  const studyCentre = useRef();
  const examCentre = useRef();
  const duration = useRef();
  const grade = useRef();
  const examDate = useRef();
  const childPsychology = useRef();
  const healthAndNutrition = useRef();
  const teachingMethodology = useRef();
  const preSchoolManagement = useRef();
  const communicativeEnglish = useRef();
  const malayalam = useRef();
  const teachingPractice = useRef();
  const commisionAndViva = useRef();
  const campTourSocialWork = useRef();
  const craftCollection = useRef();
  const remarks = useRef();

  ///////////////////////////////////
  //   generatePDF();
  ///////////////////////////////////////

  const generatePDF = async () => {
    var pdfDocument = new jsPDF("p", "pt", "a4");

    const backgroundImage = "/assets/images/pdf/marksheet_blank_page.jpeg";
    const pageWidth = pdfDocument.internal.pageSize.getWidth();
    const pageHeight = pdfDocument.internal.pageSize.getHeight();

    // Load the image
    const img = new Image();
    img.src = backgroundImage;

    img.onload = () => {
      pdfDocument.addImage(img, "JPEG", 0, 0, pageWidth, pageHeight);

      var body1 = [
        [
          {
            content: "Reg No : ",
            colSpan: 7,
            styles: { fontStyle: "bold", lineWidth: 0, halign: "right" },
          },
          {
            content: regNo.current,
            colSpan: 5,
            styles: { fontStyle: "bold", halign: "center" },
          },
        ],
        [
          {
            content: "",
            colSpan: 12,
            styles: { halign: "left", fontSize: 1, lineWidth: 0 },
          },
        ],

        [
          { content: "Name", colSpan: 3, styles: { fontStyle: "bold" } },
          { content: name.current, colSpan: 9 },
        ],
        [
          {
            content: "",
            colSpan: 12,
            styles: { halign: "left", fontSize: 2, cellPadding: 0 },
          },
        ],
        [
          { content: "Course", colSpan: 3, styles: { fontStyle: "bold" } },
          { content: course.current, colSpan: 9 },
        ],
        [
          {
            content: "",
            colSpan: 12,
            styles: { halign: "left", fontSize: 2, cellPadding: 0 },
          },
        ],

        [
          {
            content: "Study Centre",
            colSpan: 3,
            styles: { fontStyle: "bold" },
          },
          { content: studyCentre.current, colSpan: 9 },
        ],
        [
          {
            content: "",
            colSpan: 12,
            styles: { halign: "left", fontSize: 2, cellPadding: 0 },
          },
        ],

        [
          { content: "Exam Centre", colSpan: 3, styles: { fontStyle: "bold" } },
          { content: examCentre.current, colSpan: 9 },
        ],
        [
          {
            content: "",
            colSpan: 12,
            styles: { halign: "left", fontSize: 2, cellPadding: 0 },
          },
        ],

        [
          { content: "Duration", colSpan: 2, styles: { fontStyle: "bold" } },
          { content: duration.current, colSpan: 2 },
          { content: "Grade", colSpan: 2, styles: { fontStyle: "bold" } },
          { content: grade.current, colSpan: 2 },
          { content: "Exam Date", colSpan: 2, styles: { fontStyle: "bold" } },
          { content: examDate.current, colSpan: 2 },
        ],
      ];

      pdfDocument.autoTable({
        body: body1,
        margin: { left: 60, right: 60 },
        startY: 295,
        theme: "grid",
        styles: {
          fillColor: false,
          fontSize: 8,
          lineColor: 20,
          fontStyle: "bold",
        },
      });

      var body2 = [
        [
          { content: "SL NO", styles: { fontSize: 10 } },
          { content: "Subject", styles: { fontSize: 10 } },
          { content: "Remarks", styles: { fontSize: 10 } },
        ],
        [
          {
            content: "Theory",
            colSpan: 3,
            styles: { halign: "left", fontStyle: "bold" },
          },
        ],
        [1, "CHILD PSYCHOLOGY", {content: childPsychology.current, styles: {fontSize:9}}],
        [2, "HEALTH AND NUTRITION", {content: healthAndNutrition.current, styles: {fontSize:9}}],
        [3, "TEACHING METHODOLOGY", {content: teachingMethodology.current, styles: {fontSize:9}}],
        [4, "PRE-SCHOOL MANAGEMENT", {content: preSchoolManagement.current, styles: {fontSize:9}}],
        [5, "COMMUNICATIVE ENGLISH", {content: communicativeEnglish.current, styles: {fontSize:9}}],
        [6, "MALAYALAM", {content: malayalam.current, styles: {fontSize:9}}],
        [{ content: "", colSpan: 3, styles: { halign: "left", fontSize: 1 } }],
        [
          {
            content: "Practical",
            colSpan: 3,
            styles: { halign: "left", fontStyle: "bold" },
          },
        ],
        [1, "Teaching Practice", {content: teachingPractice.current, styles: {fontSize:9}}],
        [2, "Commision & Viva", {content: commisionAndViva.current, styles: {fontSize:9}}],
        [
          3,
          "Camp, Tour, Social Work & Assignment Etc...",
          {content: campTourSocialWork.current, styles: {fontSize:9}},
        ],
        [4, "Craft, Collection Etc...", {content: craftCollection.current, styles: {fontSize:9}}],
        [{ content: "", colSpan: 3, styles: { halign: "left", fontSize: 1 } }],
        [
          {
            content: "Remarks",
            styles: { halign: "center", fontStyle: "bold" },
          },
          {
            content: remarks.current,
            colSpan: 2,
            styles: { halign: "center" },
          },
        ],
      ];
      pdfDocument.setLineWidth(10);
      pdfDocument.autoTable({
        body: body2,
        margin: { left: 60, right: 60 },
        startY: 435,
        theme: "grid",
        styles: {
          fillColor: false,
          fontSize: 8,
          lineColor: 20,
          fontStyle: "bold",
        },
      });
      pdfDocument.save("Marksheet");
      resettoempty();
    };
  };

  return (
    <Fragment>
      <Header />
      <div className="map-address-section padding-tb section-bg"></div>
      <div className="contact-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            <form>
              <div className="form-group">
                <center>
                  {" "}
                  <input
                    type="text"
                    name="admission_no"
                    style={{ width: 500, backgroundColor: "lightgrey" }}
                    onChange={(e) => (regNo.current = e.target.value)}
                    placeholder="Register No *"
                  />
                </center>
              </div>
              <div style={{ height: 50 }}></div>

              <div style={{ height: 50 }}></div>
              <div className="form-group w-100 text-center">
                <input
                  type={"button"}
                  value={"Submit"}
                  style={{ width: 200, backgroundColor: "lightgreen" }}
                  // onClick={handleSubmit2}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default DownloadMarksheetPage;
