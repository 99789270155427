import { getDownloadURL, list, ref } from "firebase/storage";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { db, storage } from "../firebase";
import "./blog-2.css";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
//import Pagination from "../component/sidebar/pagination";

const blogList = [
  {
    imgUrl: "/assets/images/gallery/3.jpeg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    link: "/assets/images/gallery/3.jpeg",
  },

  {
    imgUrl: "/assets/images/gallery/7.jpeg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    link: "/assets/images/gallery/7.jpeg",
  },
  {
    imgUrl: "/assets/images/gallery/7.jpeg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    link: "/assets/images/gallery/7.jpeg",
  },
];

const BlogPage = () => {
  const [picUrls, setPhotoUrls] = useState([]);

  const getPhotos = async () => {
    var pictures = [];

    const q = query(collection(db, "Gallery"), orderBy("timestamp", "desc"));
    const snapshot = await getDocs(q);

    snapshot.docs.forEach((doc) => {
      pictures.push({
        imgUrl: doc.data().imageUrl,
        title: doc.data().title,
        desc: doc.data().description,
      });
    });

    setPhotoUrls(pictures);
  };

  useEffect(() => {
    getPhotos();
    // return;
    // const getPhotos = async () => {
    //   const folderRef = ref(storage, "gallery/");
    //   const photoList = await list(folderRef);
    //   const photoUrls = await Promise.all(
    //     photoList.items.map((itemRef) => getDownloadURL(itemRef))
    //   );
    //   // console.log(photoUrls);
    //   setPhotoUrls(photoUrls);
    // };
    // getPhotos();
  }, []);

  return (
    <Fragment>
      <Header />
      <PageHeader title={"GALLERY"} curPage={"Blog"} />
      <div className="blog-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
              {picUrls.map((val, i) => (
                <div className="col" key={i}>
                  <div className="post-item">
                    <div className="post-inner">
                      <div className="post-thumb">
                        <a href={val.imgUrl}>
                          {" "}
                          <img
                            className="image-item"
                            src={`${val.imgUrl}`}
                            alt={`${"image"}`}
                          />
                        </a>
                      </div>
                      <div>
                        <span
                          style={{
                            display: "block",
                            marginTop: 10,
                            fontWeight: "500",
                            fontSize: "20px",
                          }}
                        >
                          {`${val.title}`}
                        </span>
                        <span
                          style={{
                            display: "block",
                            color: "gray",
                          }}
                        >
                          {`${val.desc}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <Pagination /> */}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default BlogPage;
