import CountUp from "react-countup";

const subTitle = "START TO SUCCESS";
const title = "Achieve Your Goals With TSSR";

const achievementList = [
  {
    count: "07",
    desc: "Years of Education Experience",
    sign: "+",

    iconName: "icofont-clock-time",
  },
  {
    count: "92",
    desc: "Assessment Projects",
    sign: "%",

    iconName: "icofont-notification",
  },
  {
    count: "50",
    desc: "Testing Centres",
    sign: "k",
    iconName: "icofont-graduate-alt",
  },
  {
    count: "83",
    desc: "Certified Members",
    sign: "%",
    iconName: "icofont-users-alt-4",
  },
];

const achieveList = [
  {
    imgUrl: "assets/images/achive/01.png",
    imgAlt: "achive thumb rajibraj91 rajibraj",
    title: "Enroll Today",
    desc: "TSSR Council has specialized standard syllabus courses that are made by giving focus on job students will get after the course completion.",
    btnText: "Register",
    siteLink: "#",
  },
  {
    imgUrl: "assets/images/achive/02.png",
    imgAlt: "achive thumb rajibraj91 rajibraj",
    title: "Apply for Franchise here.",
    desc: "TSSR Council is Providing Franchise to institutions which conduct short term courses and skilled training courses in Technical and other disciplines.",
    btnText: "Register",
    siteLink: "#",
  },
];

const Achievement = () => {
  return (
    <div className="achievement-section style-3 padding-tb">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="counter-part">
            <div className="row g-4 row-cols-lg-4 row-cols-sm-2 row-cols-1 justify-content-center">
              {achievementList.map((val, i) => (
                <div className="col" key={i}>
                  <div className="count-item">
                    <div className="count-inner">
                      <div className="count-icon">
                        <i className={val.iconName}></i>
                      </div>
                      <div className="count-content">
                        <h2>
                          <span className="count">
                            <CountUp end={val.count} />
                          </span>
                          <span>{val.sign}</span>
                        </h2>
                        <p>{val.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievement;
