import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import { Link, NavLink } from "react-router-dom";

const subTitle = "Recognized World Wide";
const title = "Get Certified With Us";
const desc =
  "TSSR COUNCIL constituted to conduct/impart examination & certification in any part of the country of all kinds of job oriented courses with standardized syllabus designed and approved by TSSR COUNCIL authority for providing quality education and training all over India. Legality, Validity, Utility of the course is strict conformity with the constitution of India and law of the land under Central Government Act Article 19(1)G, 29 & 30. Our working area & territorial jurisdiction of the organization extends to whole of India. ";
const desc2 =
  "TSSR COUNCIL has emerged to be one of the fastest growing education franchise organisation in India with its Registered Office at New Delhi and Central Administrative Office located in the South Western tip of India, Calicut, is the city of the Indian State of Kerala, Today we have got very extensive and wide network and a leading Global Talent Development Organization with more than 1000+ Authorised Training Centres, spread all over Kerala and around in 22+ States and other parts of India & abroad, which is certified, approved, recognised & licensed under the Act of Central Govt of India. TSSR COUNCIL invites interested entrepreneurs/institutions/training centres to join hands with Indias No.1 Leading & Largest Certification Body in this unique mission and help to create a digitally enabled nation.";
const btnText = "Apply  Now";

const FeatureSubTitle = "process";
const FeatureTitle = "How It Works";


const featureList = [
   {
        imgUrl: 'assets/images/feature/05.png',
        imgAlt: 'feature rajibraj91 rajibraj',
        title: 'Normal Discussion',
        titleSpan: 'Room',
        btnText: 'TSSR Council conduct a normal discussion with the institution management about their course requirements.',
    },

    {
        imgUrl: 'assets/images/feature/02.png',
        imgAlt: 'feature rajibraj91 rajibraj',
        title: 'Find Great Solution',
        titleSpan: 'Courses',
        btnText: 'TSSR Council provide the best solution by opting best courses according the criteria management proposed.',
    },
 
   
    {
        imgUrl: 'assets/images/feature/06.png',
        imgAlt: 'feature rajibraj91 rajibraj',
        title: 'Grow Education',
        titleSpan: 'Leader',
        btnText: 'Get Franchise from TSSR Council to give quality education to students who joins the institution and thus increase the status of institution.',
    },
]

const countList = [
  {
    iconName: "icofont-users-alt-4",
    count: "12600",
    text: "Students Enrolled",
  },
  {
    iconName: "icofont-graduate-alt",
    count: "50",
    text: "Certified Trainer",
  },
  {
    iconName: "icofont-notification",
    count: "350",
    text: "Professional Courses",
  },
];

const FranchisePage = () => {
  return (
    <Fragment>
      <Header />

      <div className="pageheader-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pageheader-content text-center">
                <h1 style={{ fontFamily: "Raleway" }}>ATC (Authorised Training center)</h1>
                <div
                  className="about-subtitle1"
                  style={{ fontSize: 22, fontWeight: "normal" }}
                >
                  Get Franchise for starting a course at low <br /> investment
                  to provide quality education.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feature-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{FeatureSubTitle}</span>
            <h2 className="title">{FeatureTitle}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row g-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 justify-content-center">
              {featureList.map((val, i) => (
                <div className="col" key={i}>
                  <div className="feature-item" >
                    <div className="feature-inner"style={{backgroundColor:'#8effc6'}} >
                      <div className="feature-thumb" >
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{paddingBottom:15}}/>
                      </div>
                      <div className="feature-content">
                        <Link to="#">
                          <h5>
                            {val.title} <span>{val.titleSpan}</span>
                          </h5>
                        </Link>
                        <Link to="#" className="lab-btn-text">
                          {val.btnText} <span></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="skill-section padding-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-12">
              <div className="instructor-content">
                <span className="subtitle" style={{ color: "#000000" }}>
                  {subTitle}
                </span>
                <h2 className="title" style={{ color: "#000000" }}>
                  {title}
                </h2>
                <p style={{ fontFamily: "Fuzzy Bubbles", color: "#000000" }}>
                  {desc2}
                </p>
                {/* <Link to="/signup" className="lab-btn"  style={{color:'#ffffff'}}>
                    <span  style={{color:'#ffffff'}}>{btnText}</span>
                  </Link> */}
              </div>
            </div>
            <div style={{ width: 200 }}></div>
            <div className="col-lg-4 col-12">
              <div className="section-wrpper">
                <div className="instructor-content" style={{ marginTop: 100 }}>
                  {/* <span className="subtitle">{subTitle}</span>
                  <h2 className="title">{title}</h2> */}
                  <p style={{ fontFamily: "Fuzzy Bubbles", color: "#ffffff" }}>
                    {desc}
                  </p>
                  <NavLink
                    to="/assets/images/pdf/TSSR COURSES.pdf"
                    className="lab-btn"
                    style={{ backgroundColor: "#2eef8e" }}
                  >
                    <span>
                      <i class="material-icons" style={{}}>
                        download
                      </i>
                      <span style={{ marginLeft: 20 }}>Download Franchise</span>
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default FranchisePage;
