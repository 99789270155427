import React, { Fragment, useState } from "react";
import { db } from "../firebase";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

const title = "ATC Study Centre Verification";

const AtcVerification = () => {
  const [reg_id, setRegId] = useState("");
  const [height, setHeight] = useState(0);

  const clear = (e) => {
    document.getElementById("centre_name").innerHTML = "";
    document.getElementById("atc").innerHTML = "";
    document.getElementById("centre_head").innerHTML = "";
    document.getElementById("place").innerHTML = "";
    document.getElementById("district").innerHTML = "";
    document.getElementById("renewal").innerHTML = "";
  };

  const handle2 = async (e) => {
    const Ref = collection(db, "Franchise");
    const q = query(Ref, where("atc", "==", reg_id));
    var snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      setHeight(200);
      snapshot.docs.forEach((doc) => {
        //#console.log(doc.data());
        document.getElementById("centre_name").innerHTML =
          doc.data().centre_name;
        document.getElementById("atc").innerHTML = doc.data().atc;
        document.getElementById("centre_head").innerHTML =
          doc.data().centre_head;
        document.getElementById("place").innerHTML = doc.data().place;
        document.getElementById("district").innerHTML = doc.data().district;
        document.getElementById("renewal").innerHTML = doc.data().renewal;
      });
    } else {
      setHeight(0);
      alert("Invalid or Non-Verified ATC Code");
      clear();
    }
  };

  const style = {
    fontSize: 20,
    fontWeight: "bold",
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={title} curPage={"instructor"} />

      <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
        <div className="container">
          <div className="section-header text-center"></div>

          <form className="account-form">
            <div className="form-group">
              <input
                value={reg_id}
                onChange={(e) => setRegId(e.target.value)}
                type="text"
                name="name"
                placeholder="ATC centre code *"
              />
            </div>

            <div className="form-group text-center">
              <input type={"button"} onClick={handle2} value="submit" />
            </div>
          </form>

          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="form-group" style={{ width: 200 }}>
              <label className="admin-page-label">Centre Name : </label>
              <p id="centre_name" style={style}></p>
              <label className="admin-page-label">ATC Code : </label>
              <p id="atc" style={style}></p>
              <label className="admin-page-label">Centre Head : </label>
              <p id="centre_head" style={style}></p>
              <label className="admin-page-label">Place : </label>{" "}
              <p id="place" style={style}></p>
              <label className="admin-page-label">District : </label>{" "}
              <p id="district" style={style}></p>
              <label className="admin-page-label">Renewal : </label>{" "}
              <p id="renewal" style={style}></p>
            </div>
            <img
              src="/assets/images/cursor/verified.jpg"
              style={{ height: height, width: height }}
            />
          </div>
          <div style={{ height: 100 }}></div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default AtcVerification;
