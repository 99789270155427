import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import { Link } from "react-router-dom";
import AccreditationText from "../component/section/accreditation_text";

const subTitle = "Our Accreditations";
const title = "Accreditations & Registration";
const btnText = "Get Started Now";

const categoryList = [
    {
        imgUrl: 'assets/images/accredit/EIAC-Logo-01.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Software',
    },
    {
        imgUrl: 'assets/images/accredit/new.png',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Design & Art',
    },
    {
        imgUrl: 'assets/images/accredit/iso 9001a.png',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Photography',
    },
    {
        imgUrl: 'assets/images/accredit/download (2).png',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Language',
    },
    {
        imgUrl: 'assets/images/accredit/msme-logo.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Marketing',
    },
    {
        imgUrl: 'assets/images/accredit/IAF.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Music',
    },
    {
        imgUrl: 'assets/images/accredit/Opastaja.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Music',
    },
    {
        imgUrl: 'assets/images/accredit/download.png',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Music',
    },
    {
        imgUrl: 'assets/images/accredit/digital-india.png',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Music',
    },
    {
        imgUrl: 'assets/images/accredit/opastaja 21000a.png',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Music',
    },
    {
        imgUrl: 'assets/images/accredit/29990.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        iconName: 'icofont-brand-windows',
        title: 'Music',
    },
]


const AccreditationPage = () => {
    return (
      <Fragment>
        <Header />
  
        <div className="pageheader-section">
          <div className="container">
          
            <div className="row">
              <div className="col-12">
                <div className="pageheader-content text-center">
                  {/* <h1 style={{ fontFamily: "Raleway" }}>Franchise Proposal</h1>
                  <div
                    className="about-subtitle1"
                    style={{ fontSize: 22, fontWeight: "normal" }}
                  >
                    Get Franchise for starting a course at low <br /> investment
                    to provide quality education.
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="category-section style-4 padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle" style={{fontSize:22,color:'#000000'}}>{subTitle}</span>
                    <h1 className="title">{title}</h1>
                </div>
                <AccreditationText/>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item">
                                    <div className="">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{height:200,width:250}} />
                                        </div>
                                        {/* <div className="category-content">
                                            <div className="cate-icon">
                                                <i className={`${val.iconName}`}></i>
                                            </div>
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div className="text-center mt-5">
                        <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                    </div> */}
                </div>
            </div>
        </div>
        
        {/* <div style={{padding:40}}>

        1.  Registered under Government of Kerala, department of registration as a non profit making educational trust. <br />
2.  Registered under Government of India as a Non Profit Non Government Organization (NGO). <br />
3.  Registered under Government of India as MSME in educational sector. (Council registred under MSME Department). <br />
4.  Registered under the Indian Intellectual Property Rights office under Indian Trademarks Act 1999. <br />
5.  Quality Management System is certified as per the ISO 9001: 2015 (International Organization for Standardization)by the Absolute Quality Certification Middle East FZE, Ajman. <br />
6.  Quality Management System is Accredited by the International Accreditation Service , Brea, California, United States of America - A member of Asia Pacific Accreditation Cooperation. <br />
7.  International Accreditation Forum accredited QMS Certification. <br />
8.  Learning Management System is certified as per International Organization for Standardization standards  ISO 29990:2010 from Opastaja Quality Control USA with UBAS accreditation. <br />
9.  Certified by the ISO 21001:2018 for Management System for Educational Organizations (EOMS) from Opastaja Quality Control USA with UBAS accreditation. <br />
10.  Registered under the Department of Income Tax, Central Board of Direct Taxes. <br />
11.  Registered under the Goods and Service tax of Government of India. <br />
12.  Registered under the C V C, Government of India. <br />
13.  Certificate is registered under the Indian Intellectual Property Rights office under the Indian Copyright act 1957. <br />
14.  Professionally designed and council approved syllubus and assessment system. <br />
15.  Practically proven and certified managmenet system for the assessment of students, assessors and institutions. <br />

        </div> */}
        
  
        <Footer />
      </Fragment>
    );
  };
  
  export default AccreditationPage;
  