import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Fragment, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { auth, db } from "../../firebase";
import Login from "../../page/login";
import Header from "../layout/admin_header";

const ResultTable = () => {
  const colRef = collection(db, "Results");

  const [datas, setDatas] = useState([]);
  const [lastDoc, setLastDoc] = useState();
  const [ver, setState] = useState(false);
  var dts = [];
  var dataList = [];
  const searchWord = useRef();

  useEffect(() => {
    fetch();
  }, []);

  const fetchMore = () => {
    const q = query(colRef, orderBy("name"), startAfter(lastDoc), limit(10));
    getDocs(q).then((e) => {
      const dts = e.docs.map((dt) => dt.data());
      const lastDoc = e.docs[e.docs.length - 1];
      setDatas((datas) => [...datas, ...dts]);
      setLastDoc(lastDoc);
    });
  };

  const fetch = () => {
    setDatas([]);
    setLastDoc();
    dts = [];
    const q = query(colRef, orderBy("name"), limit(5));
    getDocs(q).then((e) => {
      const dts = e.docs.map((dt) => dt.data());
      const lastDoc = e.docs[e.docs.length - 1];
      setDatas((datas) => [...datas, ...dts]);
      setLastDoc(lastDoc);
    });
  };

  if (datas.length === 0) {
    return <h1>Loading...</h1>;
  }

  const search = (e) => {
    if (searchWord.current == "") {
      fetch();
    } else {
      dataList = [];
      const d = collection(db, "Results");
      var q = query(d,  where("reg_no", ">=", searchWord.current),where('reg_no','<=',searchWord.current+'\uf8ff'));

      getDocs(q)
        .then((e) => {
          //#console.log("name search");
          e.docs.forEach((e) => {
            dataList.push(e.data());
          });
        })
        .then(() => {
          //#console.log("id search");
          q = query(d, where("name", ">=", searchWord.current),where('name','<=',searchWord.current+'\uf8ff'));
          getDocs(q).then((e) => {
            e.docs.forEach((e) => {
              dataList.push(e.data());
            });
            setDatas(dataList);
          });
        });
    }
  };

  const delete_undefined = (e) => {
    Swal.fire({
      title: "Do you want to Delete all data in this table?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((response) => {
      if (response.isConfirmed) {
        const batch = writeBatch(db);
        var idsToDelete = [];
        const d = collection(db, "Results");

        const q = query(d, orderBy("name"));

        getDocs(q)
          .then((e) => {
            e.docs.forEach((e) => {
              idsToDelete.push(e.data().id);
            });
          })
          .then(async (e) => {
            idsToDelete.forEach((id) => {
              const docRef = doc(db, "Results", id);
              batch.delete(docRef);
            });
            await batch.commit().then((e) => {
              alert("Deleted all data!!");
              fetch();
            });
          });
      } else {
        alert("Deletion Cancelled");
      }
    });
  };

  const deleteId = (e) => {
    const ref = doc(db, "Results", e);
    if (window.confirm("Are you sure to delete ID ?")) {
      deleteDoc(ref);
      alert("Deleted");
    }
    fetch();
  };

  onAuthStateChanged(auth, (user) => {
    if (user) setState(true);
    // else setState(false);
  });

  if (ver == true)
    return (
      <Fragment>
        <Header />
        <div style={{ height: 50 }}></div>
        <center>
          <h2 className="title">Hall Ticket Table</h2>
        </center>
        <div style={{ padding: 50 }}>
          <Table striped="columns">
            <thead style={{ backgroundColor: "#77a8a8" }}>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Admission No</th>
                <th className="table-btn-layout">
                  <input
                    style={{ borderRadius: 100, marginRight: 10 }}
                    type={"search"}
                    placeholder="Search"
                    id="search"
                    defaultValue={searchWord.current}
                    onChange={(e) => (searchWord.current = e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") search();
                    }}
                  />
                  <input
                    style={{ backgroundColor: "red", fontWeight: "bolder" }}
                    type={"button"}
                    value="Delete All"
                    className="table-tbn"
                    onClick={delete_undefined}
                  />
                </th>
                {/* <ReloadIcon color="#ffffff"/> */}
              </tr>
            </thead>

            <tbody className="table-row">
              {datas.map((val, i) => (
                <tr key={i + 1}>
                  {/* <ShowData data={val.id} show={i + 1} />} */}
                  <td>{i + 1}</td>
                  <td>{val.name}</td>
                  <td>{val.reg_no}</td>

                  <td style={{ width: 400 }}>
                    <div className="table-btn-layout">
                      {/* <EditBtn data={val.id} fun={fetch} /> */}
                      <Link to={`/UsdCDd0980cdnl08KDlk09CL843Did/${val.id}`}>
                        <input
                          className="table-tbn"
                          style={{
                            backgroundColor: "lightblue",
                            color: "white",
                            fontWeight: "bold",
                          }}
                          type={"button"}
                          value="Edit"
                        />
                      </Link>
                      <input
                        className="table-tbn"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        type={"button"}
                        value="Delete"
                        onClick={() => {
                          deleteId(val.id);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <input type="button" value="Load More" onClick={fetchMore} />
        </div>
      </Fragment>
    );
  else return <Login />;
};

export default ResultTable;

export const EditResultData = () => {
  let params = useParams();
  const id = decodeURIComponent(params.id);

  const reg_no = useRef();
  const result = useRef();
  const grade = useRef();
  const course = useRef();
  const exam_centre = useRef();
  const date = useRef();
  const duration = useRef();
  const name = useRef();
  const study_centre = useRef();
  const [ver, setState] = useState(false);

  // const [ver, setState] = useState(false);

  if (reg_no.current == "") {
    return <h1>Loading</h1>;
  }

  onAuthStateChanged(auth, (user) => {
    if (user) setState(true);
    // else setState(false);
  });

  const docRef = doc(db, "Results", id);
  getDoc(docRef).then((e) => {
    reg_no.current = e.data().reg_no;
    course.current = e.data().course;
    exam_centre.current = e.data().exam_centre;
    date.current = e.data().exam_date;
    duration.current = e.data().duration;
    result.current = e.data().result;
    grade.current = e.data().grade;
    name.current = e.data().name;
    study_centre.current = e.data().study_centre;
    document.getElementById("reg_no").value = e.data().reg_no;
    document.getElementById("course").value = e.data().course;
    document.getElementById("exam_centre").value = e.data().exam_centre;
    document.getElementById("date").value = e.data().exam_date;
    document.getElementById("duration").value = e.data().duration;
    document.getElementById("name").value = e.data().name;
    document.getElementById("study_centre").value = e.data().study_centre;
    document.getElementById("result").value = e.data().result;
    document.getElementById("grade").value = e.data().grade;
  });

  if (ver == true)
    return (
      <div style={{ padding: 100 }}>
        <div style={{ height: 100 }}></div>
        <form className="contact-form" id="tssc-form">
          {/* Register No */}
          <div className="form-group">
            <label className="admin-page-label">Register No</label>
            <input
              id="reg_no"
              defaultValue={reg_no.current}
              onChange={(e) => (reg_no.current = e.target.value)}
              type="text"
              name="reg_no"
              placeholder="Register No"
            />
          </div>
          {/* Course */}
          <div className="form-group">
            <label className="admin-page-label">Course</label>
            <input
              id="course"
              defaultValue={course.current}
              onChange={(e) => (course.current = e.target.value)}
              type="text"
              name="course"
              placeholder="Course"
            />
          </div>
          {/* Exam Centre */}
          <div className="form-group">
            <label className="admin-page-label">Exam Centre</label>
            <input
              id="exam_centre"
              defaultValue={exam_centre.current}
              onChange={(e) => (exam_centre.current = e.target.value)}
              type="text"
              name="exam_centre"
              placeholder="Exam Centre"
            />
          </div>
          {/* Date */}
          <div className="form-group">
            <label className="admin-page-label">Date</label>
            <input
              id="date"
              defaultValue={date.current}
              onChange={(e) => (date.current = e.target.value)}
              type="text"
              name="date"
              placeholder="Date"
            />
          </div>
          {/*Duration*/}
          <div className="form-group">
            <label className="admin-page-label">Duration</label>
            <input
              id="duration"
              defaultValue={duration.current}
              onChange={(e) => (duration.current = e.target.value)}
              type="text"
              name="duration"
              placeholder="Duration"
            />
          </div>
          {/* Name */}
          <div className="form-group">
            <label className="admin-page-label">Name</label>
            <input
              id="name"
              defaultValue={name.current}
              onChange={(e) => {
                name.current = e.target.value;
              }}
              type="text"
              name="name"
              placeholder="Name"
            />
          </div>
          {/* Study Centre */}
          <div className="form-group">
            <label className="admin-page-label">Study Centre</label>
            <input
              id="study_centre"
              defaultValue={study_centre.current}
              onChange={(e) => (study_centre.current = e.target.value)}
              type="text"
              name="study_centre"
              placeholder="Study Centre"
            />
          </div>
          {/* Grade */}
          <div className="form-group">
            <label className="admin-page-label">Grade</label>
            <input
              id="grade"
              defaultValue={grade.current}
              onChange={(e) => (grade.current = e.target.value)}
              type="text"
              name="grade"
              placeholder="Grade"
            />
          </div>
          {/* Result */}
          <div className="form-group">
            <label className="admin-page-label">Result</label>
            <input
              id="result"
              defaultValue={result.current}
              onChange={(e) => (result.current = e.target.value)}
              type="text"
              name="result"
              placeholder="Result"
            />
          </div>
          <div className=" w-100 text-center">
            <input
              type={"button"}
              value={"submit"}
              onClick={async () => {
                await updateDoc(doc(db, "Results", id), {
                  reg_no: reg_no.current,
                  course: course.current,
                  exam_centre: exam_centre.current,
                  exam_date: date.current,
                  duration: duration.current,
                  name: name.current,
                  result: result.current,
                  grade: grade.current,
                  study_centre: study_centre.current,
                }).then(() => {
                  alert("Updated");
                });
              }}
              className="file-upload-btn"
            />
          </div>
        </form>
        <div style={{ height: 100 }}></div>
      </div>
    );
  else return <Login />;
};
