import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useRef, useState } from "react";
import { Fragment } from "react";
import { db, storage } from "../../firebase";
import Footer from "../layout/footer";
import Header from "../layout/header";
import { PDFDocument, rgb } from "pdf-lib";

const conSubTitle = "Register";
const conTitle = "Fill The Form Below To Register For Examination.";
const btnText = "Send our Message";

const contactList = [
  {
    imgUrl: "assets/images/icon/01.png",
    imgAlt: "contact icon",
    title: "Office Address",
    desc: "1201 park street, Fifth Avenue",
  },
  {
    imgUrl: "assets/images/icon/02.png",
    imgAlt: "contact icon",
    title: "Phone number",
    desc: "+22698 745 632,02 982 745",
  },
  {
    imgUrl: "assets/images/icon/03.png",
    imgAlt: "contact icon",
    title: "Send email",
    desc: "adminedukon@gmil.com",
  },
  {
    imgUrl: "assets/images/icon/04.png",
    imgAlt: "contact icon",
    title: "Our website",
    desc: "www.edukon.com",
  },
];

const RegisterPage = () => {
  const admission_no = useRef();
  const name = useRef();
  const course = useRef();
  const study_centre = useRef();
  const exam_centre = useRef();
  const exam_date = useRef();
  const exam_time = useRef();
  const photo = useRef();

  const file = useRef();

  const closeButtonStyles = `
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #dd7973;
  color: white;
  font-weight: bold;
  margin: 10px;
`;

  const continueButtonStyles = `
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #4681f4;
  color: white;
  font-weight: bold;
  margin: 10px;
`;

  // const deleteDuplicates = async (e) => {
  //   const field = "admission_no";
  //   const collectionRef = collection(db, "HT_Data");
  //   const snapshot = await getDocs(collectionRef);
  //   const uniqueValues = new Set();
  //   const batch = writeBatch(db);

  //   snapshot.docs.forEach((doc) => {
  //     const fieldValue = doc.get(field);
  //     if (!uniqueValues.has(fieldValue)) {
  //       uniqueValues.add(fieldValue);
  //     } else {
  //       // Add the document reference to the batch for deletion
  //       batch.delete(doc.ref);
  //     }
  //   });

  //   // Execute the batch operation to delete the duplicate documents
  //   await batch.commit();
  // };

  const handleSubmit2 = async (e) => {
    if (admission_no.current != undefined) {
      const q = query(
        collection(db, "HT_Data"),
        where("admission_no", "==", admission_no.current)
      );
      const snapshot = await getDocs(q);
      if (snapshot.docs.length > 0) {
        const studentData = snapshot.docs[0];
        if (
          studentData.data()["url"] == undefined ||
          studentData.data()["url"] == "none"
        ) {
          uploadPhotoDialog(snapshot.docs[0]);
        } else {
          photo.current = studentData.data().url;
          admission_no.current = studentData.data().admission_no;
          name.current = studentData.data().name;
          course.current = studentData.data().course;
          study_centre.current = studentData.data().study_centre;
          exam_centre.current = studentData.data().exam_centre;
          exam_date.current = studentData.data().exam_date;
          exam_time.current = studentData.data().exam_time;
          generatePDF();
        }
      } else {
        alert("Admission number not found");
      }
    } else {
      alert("Please enter a valid admission number.");
    }
  };

  async function uploadPhotoDialog(s, atc, student_doc_id) {
    const dialog = document.createElement("div");
    dialog.classList.add("upload-dialog"); // Add a class for easy identification
    dialog.style.position = "fixed";
    dialog.style.top = "0";
    dialog.style.left = "0";
    dialog.style.width = "100%";
    dialog.style.height = "100%";
    dialog.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    dialog.style.display = "flex";
    dialog.style.justifyContent = "center";
    dialog.style.alignItems = "center";

    const content = document.createElement("div");
    content.style.backgroundColor = "#fff";
    content.style.padding = "20px";
    content.style.borderRadius = "8px";

    const title = document.createElement("h4");
    title.innerText = "Upload Photo";

    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleFileChange);

    const closeButton = document.createElement("button");
    closeButton.style = closeButtonStyles;
    closeButton.innerText = "Close";
    closeButton.addEventListener("click", closeDialog);

    const continueButton = document.createElement("button");
    continueButton.style = continueButtonStyles;
    continueButton.innerText = "Continue";
    continueButton.addEventListener("click", () =>
      continueDialog(s, atc, student_doc_id)
    );

    content.appendChild(title);
    content.appendChild(fileInput);
    content.appendChild(closeButton);
    content.appendChild(continueButton);

    dialog.appendChild(content);
    document.body.appendChild(dialog);
  }

  function handleFileChange(event) {
    const thisFile = event.target.files[0];
    if (thisFile) {
      file.current = thisFile;
    }
  }

  function closeDialog() {
    const dialog = document.querySelector(".upload-dialog");
    if (dialog) {
      dialog.parentNode.removeChild(dialog);
      return;
    }
  }

  function continueDialog(s) {
    const dialog = document.querySelector(".upload-dialog");
    if (dialog) {
      if (file.current != undefined) {
        const storageRef = ref(
          storage,
          "temp_photo/" + encodeURIComponent(admission_no.current)
        ); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file.current);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => console.log(err),
          async () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
              await updateDoc(doc(db, "HT_Data", s.id), {
                url: url,
              });
              photo.current = url;
              admission_no.current = s.data().admission_no;
              name.current = s.data().name;
              course.current = s.data().course;
              study_centre.current = s.data().study_centre;
              exam_centre.current = s.data().exam_centre;
              exam_date.current = s.data().exam_date;
              exam_time.current = s.data().exam_time;
              generatePDF();
            });
          }
        );
        dialog.parentNode.removeChild(dialog);
        return;
      }
    }
  } // implement continue button functionality

  async function generatePDF() {
    // Create a new document and add a new page
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();

    let img = await fetch("/assets/images/pdf/pdf_temp.jpg").then((res) =>
      res.arrayBuffer()
    );
    img = await pdfDoc.embedJpg(img);

    let photo_url = await fetch(photo.current + ".jpg").then((res) =>
      res.arrayBuffer()
    );
    try {
      photo_url = await pdfDoc.embedJpg(photo_url);
    } catch (e) {
      photo_url = await pdfDoc.embedPng(photo_url);
    }

    const { width, height } = img.scale(0.1);
    page.drawImage(img, {
      x: 0,
      y: 0,
      height: page.getHeight(),
      width: page.getWidth(),
    });

    // Get the width and height of the page

    // Draw a string of text toward the top of the page

    page.drawImage(photo_url, {
      x: 460,
      y: 530,
      height: 120,
      width: 100,
    });

    //admission no
    page.drawText(admission_no.current, {
      x: 195,
      y: 660,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //name
    page.drawText(name.current, {
      x: 195,
      y: 630,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //course
    page.drawText(course.current, {
      x: 195,
      y: 600,
      size: 10,
      color: rgb(0, 0, 0),
    });

    //study centre
    page.drawText(study_centre.current, {
      x: 195,
      y: 568,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //exam centre
    page.drawText(exam_centre.current, {
      x: 195,
      y: 535,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //date
    page.drawText(exam_date.current, {
      x: 195,
      y: 507,
      size: 12,
      color: rgb(0, 0, 0),
    });

    //time
    page.drawText(exam_time.current, {
      x: 195,
      y: 475,
      size: 12,
      color: rgb(0, 0, 0),
    });

    // Write the PDF to a file
    const pdfData = await pdfDoc.save();
    const blob = new Blob([pdfData], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "hall_ticket.pdf";
    document.body.appendChild(link);
    link.click();
  }

  const handleSubmit = (e) => {
    // const q = query(
    //   collection(db, "HT_Data"),
    //   where("admission_no", "==", admission_no.current)
    // );
    // onSnapshot(q, (snapshotF) => {
    //   ////
    //   if (admission_no.current != undefined && snapshotF.docs.length > 0) {
    //     if (!file) {
    //       alert("Please upload an image first!");
    //       return;
    //     }
    //     const storageRef = ref(
    //       storage,
    //       "photo/" + encodeURIComponent(admission_no.current)
    //     ); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    //     const uploadTask = uploadBytesResumable(storageRef, file);
    //     uploadTask.on(
    //       "state_changed",
    //       (snapshot) => {
    //         const percent = Math.round(
    //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //         ); // update progress
    //         setPercent(percent);
    //         getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //           console.log("getdowndurl");
    //           snapshotF.docs.forEach((f) => {
    //             updateDoc(doc(db, "HT_Data", f.data().id), {
    //               url: url,
    //             }).then((e) => {
    //               console.log("url");
    //               // alert("Request Sent");
    //               window.location.reload(false);
    //             });
    //           });
    //         });
    //       },
    //       (err) => console.log(err),
    //       () => {
    //         // download url
    //         // getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //         //   console.log("getdowndurl");
    //         //   snapshotF.docs.forEach((f) => {
    //         //     updateDoc(doc(db, "HT_Data", f.data().id), {
    //         //       url: url,
    //         //     }).then((e) => {
    //         //       console.log("url");
    //         //       alert("Request Sent");
    //         //       window.location.reload(false);
    //         //     });
    //         //   });
    //         // });
    //       }
    //     );
    //   } else if (snapshotF.docs.length <= 0) {
    //     alert("Invalid Admission No");
    //   } else {
    //     alert("Please fill all details");
    //   }
    //   /////
    // });
  };

  const resettoempty = () => {
    admission_no.current = "";
  };

  // const [file, setFile] = useState("");
  // const [percent, setPercent] = useState(0);

  // function handleChange(event) {
  //   setFile(event.target.files[0]);
  // }

  ///////////////////////////////////
  // generatePDF();
  ///////////////////////////////////////

  return (
    <Fragment>
      <Header />
      <div className="map-address-section padding-tb section-bg"></div>
      <div className="contact-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            <form>
              <div className="form-group">
                <center>
                  {" "}
                  <input
                    type="text"
                    name="admission_no"
                    style={{ width: 500, backgroundColor: "lightgrey" }}
                    onChange={(e) => (admission_no.current = e.target.value)}
                    placeholder="Admission Number *"
                  />
                </center>
              </div>
              <div style={{ height: 50 }}></div>
              {/* <input type="file" onChange={handleChange} accept="/image/*" /> */}
              {/* <div style={{ height: 50 }}></div> */}
              <div className="form-group w-100 text-center">
                <div className=" w-100 text-center">
                  {/* <button className="lab-btn" onSubmit={handleSubmit}>
                  <span>{btnText}</span>
                </button> */}
                  <input
                    type={"button"}
                    value={"submit"}
                    onClick={handleSubmit2}
                    className="file-upload-btn"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default RegisterPage;
