import React, { Fragment, Component } from "react";
import Footer from "../layout/footer";
import { db, tssrCol } from "../../firebase";
import { addDoc, collection, deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { GoogleAuthProvider } from "firebase/auth";
import * as XLSX from "xlsx";
import { SheetJSFT } from "../../file_read/types";
import { make_cols } from "../../file_read/MakeColumns";
import Login from "../../page/login";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import { useRef } from "react";
import Header from "../layout/admin_header";

const conSubTitle = "";
const conTitle = "Study Centre Upload Page";
const btnText = "submit";

const StudyCentreUpload = () => {
  // const [reg_no, setRegNo] = useState("");
  // const [name, setName] = useState("");
  // const [course, setCourse] = useState("");
  // const [study_centre, setStudyCentre] = useState("");
  // const [exam_centre, setExamCentre] = useState("");
  // const [date, setDate] = useState("");
  // const [result, setResult] = useState("");
  // const [grade, setGrade] = useState("");

  const ATC_code = useRef();
  const centre_head = useRef();
  const centre_name = useRef();
  const district = useRef();
  const place = useRef();
  const renewal = useRef();


  const handleSubmit = (e) => {
    addDoc(collection(db,'Franchise'), {})
      .then((e) => {
        setDoc(doc(db, "Franchise", e.id), {
          id: e.id,
          ATC_code:ATC_code.current,
        centre_head:centre_head.current,
        centre_name:centre_name.current,
        district:district.current,
        place:place.current,
        renewal:renewal.current
        }).then(() => {
          alert("Data Uploaded");
          document.getElementById("tssr-form").reset();
          resettoempty()
        });
      })
      .catch((error) => {
        alert(error.message);
      });

    // addDoc(collection(db,'Franchise'),{
    //     ATC_code:ATC_code.current,
    //     centre_head:centre_head.current,
    //     centre_name:centre_name.current,
    //     district:district.current,
    //     place:place.current,
    //     renewal:renewal.current
    // })
  };

  const resettoempty=()=>{
   ATC_code.current=""
   centre_head.current=""
   centre_name.current=""
   district.current=""
   place.current=""
   renewal.current=""
  }

  // const test = (e) => {
  //   items.forEach(function(obj){
  //     addDoc(tssrCol,{
  //       reg_no:obj.reg_id,
  //       name:obj.name,
  //       course:obj.course,
  //       study_centre:obj.study_centre,
  //       exam_centre:obj.exam_centre,
  //       date:obj.date,
  //       result:obj.result,
  //       grade:obj.grade
  //     }).then(function(docRef){
  //       //#console.log("ID : ",docRef.id)
  //     }).catch(function(error){
  //       //#console.log(error)
  //     });
  //   });
  // }

  // const readUploadFile = (d)=>{
  //   //#console.log('pressed')
  //   const reader = new FileReader();
  //     reader.onload=(e)=>{
  //       const data = e.target.result;
  //           const workbook = x.read(data, { type: "array" });
  //           const sheetName = workbook.SheetNames[0];
  //           const worksheet = workbook.Sheets[sheetName];
  //           const json = x.utils.sheet_to_json(worksheet);
  //           //#console.log(json);
  //     }
  // }


  const [ver, setState] = useState(false);
  onAuthStateChanged(auth, (user) => {
    if (user) setState(true);
    // else setState(false);
  });

 

  if (ver == true)
    return (
      <Fragment>
        <Header/>
        <div className="contact-section padding-tb">
          <div className="container">
            <div className="section-header text-center">
              <span className="subtitle">{conSubTitle}</span>
              <h2 className="title">{conTitle}</h2>
            </div>
            <div className="section-wrapper">
              <form className="contact-form" id="tssr-form">
                {/* ///////////////////////// */}
                {/* ATC Code * */}
                <div className="form-group">
                  <label className="admin-page-label">ATC Code</label>
                  <input
                    id="atc"
                    value={ATC_code.current}
                    onChange={(e) => (ATC_code.current = e.target.value)}
                    type="text"
                    name="name"
                    placeholder="ATC Code *"
                  />
                </div>
                {/* Centre Head */}
                <div className="form-group">
                  <label className="admin-page-label">Centre Head</label>
                  <input
                    id="Centre Head"
                    value={centre_head.current}
                    onChange={(e) => (centre_head.current = e.target.value)}
                    type="text"
                    name="Centre Head"
                    placeholder="Centre Head"
                  />
                </div>
                {/* Centre Name */}
                <div className="form-group">
                  <label className="admin-page-label">
                    Centre Name
                  </label>
                  <input
                    id="Centre Name"
                    value={centre_name.current}
                    onChange={(e) => (centre_name.current = e.target.value)}
                    type="text"
                    name="Centre Name"
                    placeholder="Centre Name"
                  />
                </div>
                {/* District  */}
                <div className="form-group">
                  <label className="admin-page-label">
                    District
                  </label>
                  <input
                    id="exm"
                    value={district.current}
                    onChange={(e) => (district.current = e.target.value)}
                    type="text"
                    name="District"
                    placeholder="District"
                  />
                </div>
                {/* Place  */}
                <div className="form-group">
                  <label className="admin-page-label">Place</label>
                  <input
                    id="Place"
                    value={place.current}
                    onChange={(e) => (place.current = e.target.value)}
                    type="text"
                    name="Place"
                    placeholder="Place"
                  />
                </div>
                {/*Renewal*/}
                <div className="form-group">
                  <label className="admin-page-label">
                    Renewal
                  </label>
                  <input
                    id="Renewal"
                    value={renewal.current}
                    onChange={(e) => (renewal.current = e.target.value)}
                    style={{ color: "#000000" }}
                    type="date"
                    name="Renewal"
                    placeholder="Renewal"
                  />
                </div>
                
              
                {/* submit button  */}
                <div className=" w-100 text-center">
                  {/* <button className="lab-btn" onSubmit={handleSubmit}>
                  <span>{btnText}</span>
                </button> */}
                  <input
                    type={"button"}
                    value={"submit"}
                    onClick={handleSubmit}
                    className="file-upload-btn"
                  />
                </div>
                {/* ///////////////////////////////// */}
              </form>
              {/* <input type={'button'} value={'test'} onClick={test}/> */}
              <br />
              <br />
             
              <center>
                <h2>OR</h2>
              </center>
              <h5 htmlFor="upload">Upload File</h5>
              <ExcelReader />
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </Fragment>
    );
  else return <Login />;
};

export default StudyCentreUpload;

class ExcelReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: [],
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  }

  handleFile() {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
        //#console.log(JSON.stringify(this.state.data, null, 2));
        this.bulkUpload(this.state.data);
      });
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }

 

  
  bulkUpload = (e) => {
    e.forEach(function (obj) {
      addDoc(collection(db,'Franchise'), {})
      .then((e) => {
        setDoc(doc(db, "Franchise", e.id), {
          id: e.id,
          ATC_code:obj.ATC_code,
        centre_head:obj.centre_head,
        centre_name:obj.centre_name,
        district:obj.district,
        place:obj.place,
        renewal:obj.renewal
        })
      })
      .catch((error) => {
        alert(error.message);
      });
    })
    alert('Data Uploaded (Group)')
    // document.getElementById("file").reset();
  };


  

  render() {
    return (
      <div className="contact-form">
        <label htmlFor="file">Upload an excel to Process Triggers</label>
        <br />
        <input
          type="file"
          className="form-control"
          id="file"
          accept={SheetJSFT}
          onChange={this.handleChange}
        />
        <br />
        <input
          type="button"
          className="file-upload-btn"
          value="Confirm and Submit"
          onClick={this.handleFile}
        />
      </div>
    );
  }
}
