import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";


//const subTitle = "";
const title = "Protected: ONLINE EXAM  ";
const desc = "This content is password protected. To view it please enter your password below:";
const btnText = "Submit Now";

// const year = "7+";
// const experience = "Years Of Experiences";


//const title = "Login";
//const socialTitle = "Login With Social Media";



const socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]

const LoginPage = () => {
    return (
        <Fragment>
        <Header />
        <PageHeader title={'TSSR COUNCIL'} curPage={'About'} />
        <div className="about-section style-3 padding-tb section-bg">
            <div className="container">
                <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
                <div className="col">
                            <div className="about-right">
                                <div className="section-header">
                                    {/* <span className="subtitle">{subTitle}</span> */}
                                    <h2 className="title">{title}</h2>
                                    <p>{desc}</p>
                                </div>
                                </div>
                                </div>
                            {/* <div className="about-thumb">
                                <img src="assets/images/about/01.jpg" alt="about" />
                            </div>
                            <div className="abs-thumb">
                                <img src="assets/images/about/02.jpg" alt="about" />
                            </div> */}
                            {/* <div className="about-left-content">
                                <h3>{year}</h3>
                                <p>{experience}</p>
                            </div> */}
                    
                    

                    

                        <form className="account-form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Register Name *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password *"
                                />
                            </div>
                            <div className="form-group">
                                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                    <div className="checkgroup">
                                        <input type="checkbox" name="remember" id="remember" />
                                        <label htmlFor="remember">Remember Me</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group text-center">
                                <button className="d-block lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form>
                       
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default LoginPage;
 