import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDZD8TXhaE6lr29R4no573qydMzUAL597Y",

  authDomain: "tssr-79f4a.firebaseapp.com",

  projectId: "tssr-79f4a",

  storageBucket: "tssr-79f4a.appspot.com",

  messagingSenderId: "465121616495",

  appId: "1:465121616495:web:f5575743436a14e786e39a",
};

const app = initializeApp(firebaseConfig);

var db = getFirestore();
var auth = getAuth(app);

const tssrCol = collection(db, "TSSR_Data");
const tsscCol = collection(db, "TSSC_Data");
const HTCol = collection(db, "HT_Data");
const storage = getStorage(app);

export { tssrCol, tsscCol, HTCol, storage, db, auth };
