
import { BrowserRouter, Routes, Route, Router, HashRouter } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
// import BlogPage from "./page/blog";
import BlogPageTwo from "./page/blog-2";
// import BlogPageThree from "./page/blog-3";
import BlogSingle from "./page/blog-single";
// import CartPage from "./page/cart-page";
// import ContactPage from "./page/contact";
import CoursePage from "./page/course";
import CourseSingle from "./page/course-single";
import CourseView from "./page/course-view";
// import ForgetPass from "./page/forgetpass";
import Home from "./page/home";
// import HomeTwo from "./page/home-2";
// import HomeThree from "./page/home-3";
// import HomeFour from "./page/home-4";
// import HomeFive from "./page/home-5";
// import HomeSix from "./page/home-6";
// import HomeSeven from "./page/home-7";
// import InstructorPage from "./page/tsscResult";
// import LoginPage from "./page/login";
// import SearchNone from "./page/search-none";
// import SearchPage from "./page/search-page";
// import ShopPage from "./page/shop";
// import ShopDetails from "./page/shop-single";
// import SignupPage from "./page/signup";
// import TeamPage from "./page/team";
import Pagination from "./component/sidebar/pagination";
import TeamSingle from "./page/tsccPolicy";
import Contact_us from "./component/section/contact_us";
import ComingSoonPage from "./page/coming-soon";
import FranchisePage from "./page/franchise";
import AccreditationPage from "./page/accreditation";
import TsccDownloads from "./page/tsccDownloads";
import TsccPolicy from "./page/tsccPolicy";
import TsccResult from "./page/Result";
import TsccBanner from "./component/section/tscchome";
import TsccVerfication from "./page/tsccVerfication";
import AboutUsPage from "./page/contact";
import StateFranchise from "./page/stage_franchise";
import TssrVerfication from "./page/tssrVerification";
import TsccAccreditationPage from "./page/tscc_accreditations";
import TssrAdminUploadPage from "./component/section/tssr_admin_upload_page";
import TssrShowResult from "./page/tssrVerification";
import Login from "./page/login";
import TsscAdminUploadPage from "./component/section/tssc_admin_upload_page";
import AdminPanel from "./component/section/admin_panel";
import AtcVerification from "./page/atc-verification";
import TssrTable, { EditTssrTable } from "./component/section/tssr_admin_table";
import TsscTable, { EditTsscTable } from "./component/section/tssc_admin_table";
import StudyCentreUpload from "./component/section/study_centre_upload";
import StudyCentreTable, { EditStudyCentre } from "./component/section/study_centre_table";
import ResultTable, { EditResultData } from "./component/section/result_admin_table";
import ResultAdminUploadPage from "./component/section/result_upload_page";
import RegisterPage from "./component/section/register_ticket_page";
import TssrHallTicketRequest from "./component/section/admin_HT_Req";
import DownloadHTPage from "./component/section/download_ht";
import HallTicketTable, { EditHallTKT } from "./component/section/hall_tkt_table";
import AdminGalleryUpload from "./component/section/admin_gallery_upload";
import AdminGalleryShow from "./component/section/admin_gallery_show";
import AtcRegRequestPage from "./component/section/atc_reg_request_page";
import DownloadMarksheetPage from "./component/section/download_marksheet";






export default function App() {
	return (
		<HashRouter class='browser-router' basename="/">

			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				{/* <Route path="index-2" element={<HomeTwo />} />
				<Route path="index-3" element={<HomeThree />} />
				<Route path="index-4" element={<HomeFour />} />
				<Route path="index-5" element={<HomeFive />} />
				<Route path="index-6" element={<HomeSix />} />
				<Route path="index-7" element={<HomeSeven />} /> */}
				<Route path="course" element={<CoursePage />} />
				<Route path="course-single" element={<CourseSingle />} />
				<Route path="course-view" element={<CourseView />} />
				{/* <Route path="blog" element={<BlogPage />} /> */}
				<Route path="gallery" element={<BlogPageTwo />} />
				<Route path="online-verification" element={<TssrVerfication />} />
				<Route path="tssr-downloads" element={<BlogSingle />} />
				<Route path="online-examination" element={<AboutPage />} />
				{/* <Route path="team" element={<TeamPage />} /> */}
				<Route path="team-single" element={<TeamSingle />} />
				<Route path="check-result" element={<TsccResult />} />
				{/* <Route path="shop" element={<ShopPage />} /> */}
				{/* <Route path="shop-single" element={<ShopDetails />} /> */}
				{/* <Route path="cart-page" element={<CartPage />} /> */}
				{/* <Route path="search-page" element={<SearchPage />} /> */}
				{/* <Route path="search-none" element={<SearchNone />} /> */}
				<Route path="about_us" element={<AboutUsPage />} />
				{/* <Route path="login" element={<LoginPage />} /> */}
				{/* <Route path="signup" element={<SignupPage />} /> */}
				{/* <Route path="forgetpass" element={<ForgetPass />} /> */}
				<Route path="*" element={<Home />} />
				<Route path="contact_us" element={<Contact_us />} />
				<Route path="Pagination" element={<Pagination />} />

				<Route path="coming_soon" element={<ComingSoonPage />} />
				<Route path="franchise" element={<FranchisePage />} />
				<Route path="accreditation" element={<AccreditationPage />} />

				<Route path="tscc-accreditation" element={<TsccAccreditationPage />} />
				<Route path="tscc-downloads" element={<TsccDownloads />} />
				<Route path="tssc-policy" element={<TsccPolicy />} />
				<Route path="tscc-result" element={<TsccResult />} />
				<Route path="tscc-verfication" element={<TsccVerfication />} />
				<Route path="tssr-verification" element={<TssrVerfication />} />
				<Route path="tscc-home" element={<TsccBanner />} />

				<Route path="stage-franchise" element={<StateFranchise />} />
				<Route path="tssr-show-result" element={<TssrShowResult />} />
				<Route path="atc-verification" element={<AtcVerification />} />
				<Route path="register_form" element={<RegisterPage />} />
				<Route path="download_ht" element={<DownloadHTPage />} />
				<Route path="download_marksheet" element={<DownloadMarksheetPage />} />
				<Route path="atc_registration" element={<AtcRegRequestPage />} />

				{/* <Route path="testTable" element={<TestTable/>}/> */}
				<Route path="/4uCN6i67N6i67KSkKSkQLdN6i68did/:id" element={<EditHallTKT />} />
				<Route path="/UsdCDd0980cdnl08KDlk09CL843Did/:id" element={<EditResultData />} />
				<Route path="/XdN6i67KSkbJZOpXQLdvnWCM98dfid/:id" element={<EditStudyCentre />} />
				<Route path="/CQCGlsmiYoLGkbJZOpXQpON6i6cdid/:id" element={<EditTsscTable />} />
				<Route path="/voCP3iSc4uCQCGlsmiYbOhfiW4idid/:id" element={<EditTssrTable />} />


				<Route path="GHK2lVD8mwfysewQgacIIwV0AjL2" element={<TssrAdminUploadPage />} />
				<Route path="AopoLGkbJZOpXQLdvnWCMlV5AnQ2" element={<TsscAdminUploadPage />} />
				<Route path="Yxr79TOhfiW4ipON6i67KSMeS9D3" element={<AdminPanel />} />
				<Route path="UsdCDd0980cdnl08KDlk09CL843D" element={<ResultTable />} />
				<Route path="BvoiDL087cDSD070DScds09CD09c" element={<ResultAdminUploadPage />} />
				<Route path="voCP3iSc4uCQCGlsmiYbOhfiW4id" element={<TssrTable />} />
				<Route path="CQCGlsmiYoLGkbJZOpXQpON6i6cd" element={<TsscTable />} />
				<Route path="l3ZAAAQeolnNkl098dfcdCDCDl09" element={<StudyCentreUpload />} />
				<Route path="XdN6i67KSkbJZOpXQLdvnWCM98df" element={<StudyCentreTable />} />
				<Route path="CP3iSc4uCN6i67KSkQLdN6i688d9" element={<TssrHallTicketRequest />} />
				<Route path="4uCN6i67N6i67KSkKSkQLdN6i68d" element={<HallTicketTable />} />
				<Route path="FuCoci67anel7KcCKSkQ63NKSkQL" element={<AdminGalleryUpload />} />
				<Route path="67anel7Kc7anel7KcCKSkQ63NKS5" element={<AdminGalleryShow />} />
				<Route path="/login" element={<Login />} />
			</Routes>
		</HashRouter>
	);
}



//tssr2

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDjFbqV5Yr2loYvwUGa3k8wMl4sA1WTatQ",
//   authDomain: "tssr2-90b12.firebaseapp.com",
//   projectId: "tssr2-90b12",
//   storageBucket: "tssr2-90b12.appspot.com",
//   messagingSenderId: "514625023743",
//   appId: "1:514625023743:web:56fae7ec0a55162a9026ca"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);


// original 
// const firebaseConfig = {
// 	apiKey: "AIzaSyANP8oL4X6qhzz5c4IXXiqzHEpP4a2GdqM",
// 	authDomain: "tssrcouncil-d806d.firebaseapp.com",
// 	projectId: "tssrcouncil-d806d",
// 	storageBucket: "tssrcouncil-d806d.appspot.com",
// 	messagingSenderId: "438354670871",
// 	appId: "1:438354670871:web:439b498786b0a8fb18b352"
//   };


//tssr3
// apiKey: "AIzaSyDGZj5DgtYFDAw5iRUwMffVFcIdmpbvdw4",

//   authDomain: "tssr3-1244b.firebaseapp.com",

//   projectId: "tssr3-1244b",

//   storageBucket: "tssr3-1244b.appspot.com",

//   messagingSenderId: "309887172988",

//   appId: "1:309887172988:web:5da66457f49fc228f864f9",