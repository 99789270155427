import { onAuthStateChanged } from "firebase/auth";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import Login from "../../page/login";
import Header, { Heade } from "../layout/admin_header";

const AdminPanel = () => {
  const btnStyle = {
    height: 100,
    width: 200,
    backgroundColor: "#222222",
    color: "#ffffff",
  };

  const [ver, setState] = useState(false);
  onAuthStateChanged(auth, (user) => {
    if (user) setState(true);
    else setState(false);
  });

  if (ver == true)
    return (
      <Fragment>
        <Header />
        <center>
          <div>
            <div style={{ height: 170 }}></div>
            <div className="admin-panel-layout">
              {" "}
              <Link to={"/GHK2lVD8mwfysewQgacIIwV0AjL2"}>
                <input
                  type={"button"}
                  value="TSSR Data Upload"
                  style={btnStyle}
                />
              </Link>
              <Link to={"/voCP3iSc4uCQCGlsmiYbOhfiW4id"}>
                <input type={"button"} value="TSSR Table" style={btnStyle} />
              </Link>
            </div>
            <div className="admin-panel-layout">
              <Link to={"/AopoLGkbJZOpXQLdvnWCMlV5AnQ2"}>
                <input
                  type={"button"}
                  value="TSCC Data Upload"
                  style={btnStyle}
                />
              </Link>
              <Link to={"/CQCGlsmiYoLGkbJZOpXQpON6i6cd"}>
                <input type={"button"} value="TSCC Table" style={btnStyle} />
              </Link>
            </div>
            <div className="admin-panel-layout">
              <Link to={"/l3ZAAAQeolnNkl098dfcdCDCDl09"}>
                <input
                  type={"button"}
                  value="Study Centre Upload"
                  style={btnStyle}
                />
              </Link>
              <Link to={"/XdN6i67KSkbJZOpXQLdvnWCM98df"}>
                <input
                  type={"button"}
                  value="Study Centre Table"
                  style={btnStyle}
                />
              </Link>
            </div>
            <div className="admin-panel-layout">
              <Link to={"/BvoiDL087cDSD070DScds09CD09c"}>
                <input type={"button"} value="Result Upload" style={btnStyle} />
              </Link>
              <Link to={"/UsdCDd0980cdnl08KDlk09CL843D"}>
                <input type={"button"} value="Result Table" style={btnStyle} />
              </Link>
            </div>
            <div className="admin-panel-layout">
              <Link to={"/CP3iSc4uCN6i67KSkQLdN6i688d9"}>
                <input
                  type={"button"}
                  value="Hall Ticket Upload"
                  style={btnStyle}
                />
              </Link>
              <Link to={"/4uCN6i67N6i67KSkKSkQLdN6i68d"}>
                <input
                  type={"button"}
                  value="Hall Ticket Table"
                  style={btnStyle}
                />
              </Link>
            </div>
            <div className="admin-panel-layout">
              <Link to={"/FuCoci67anel7KcCKSkQ63NKSkQL"}>
                <input
                  type={"button"}
                  value="Gallery Upload"
                  style={btnStyle}
                />
              </Link>
            </div>
            <div style={{height:200}}></div>
          </div>
        </center>
      </Fragment>
    );
  else return <Login />;
};

export default AdminPanel;


