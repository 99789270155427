import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Popup from "reactjs-popup";

const phoneNumber = "+91 9400867461";
const address = "Engapuzha, Calicut, Kerala, India";

let socialList = [
  {
    iconName: "icofont-facebook-messenger",
    siteLink: "#",
  },
  {
    iconName: "icofont-twitter",
    siteLink: "#",
  },
  {
    iconName: "icofont-vimeo",
    siteLink: "#",
  },
  {
    iconName: "icofont-skype",
    siteLink: "#",
  },
  {
    iconName: "icofont-rss-feed",
    siteLink: "#",
  },
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li>
                <p>Find us on : </p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <NavLink to={val.siteLink}>
                    <i className={val.iconName}></i>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo/top_logo.png" alt="logo" />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li className="menu-item-has-children">
                    <a
                      style={{ color: "grey" }}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Home
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <a href="/#/tscc-home">TSCC</a>
                      </li>
                      <li>
                        <a href="/">TSSR</a>
                      </li>
                    </ul>
                  </li>

                  {/* <li>
                        <NavLink to="/index-2">Home Two</NavLink>
                      </li>
                      <li>
                        <NavLink to="/index-3">Home Three</NavLink>
                      </li>
                      <li>
                        <NavLink to="/index-4">Home Four</NavLink>
                      </li>
                      <li>
                        <NavLink to="/index-5">Home Five</NavLink>
                      </li>
                      <li>
                        <NavLink to="/index-6">Home Six</NavLink>
                      </li>
                      <li>
                        <NavLink to="/index-7">Home Seven</NavLink>
                      </li>
                    </ul> 
                  </li> */}
                  <li>
                    <NavLink to="/about_us" style={{ color: "grey" }}>
                      About
                    </NavLink>
                  </li>
                  <li className="menu-item-has-children">
                    <a
                      style={{ color: "grey" }}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Courses
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <a href="/assets/images/pdf/ACADEMIC-CALENDARS-TSSR.pdf">
                          Academic Calender
                        </a>
                      </li>
                      <li>
                        <NavLink to="/course">TSSR Courses</NavLink>
                      </li>
                      <li>
                        <a href="/assets/images/pdf/STUDENT_AFFIDEVIT_tssr.pdf">
                          Students Affidavit
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <a
                      style={{ color: "grey" }}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Services
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/franchise">Franchise</NavLink>
                      </li>
                      <li>
                        <NavLink to="/stage-franchise">State Franchise</NavLink>
                      </li>
                      <li>
                        <NavLink to="/accreditation">Accreditations</NavLink>
                      </li>
                      <li>
                        <NavLink to="/online-verification">
                          Certificate Verfication
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/atc-verification">
                          Authorised Study Centres
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/coming_soon">Careers</NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/tssr-downloads">Downloads</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/blog-single">Messages</NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/coming_soon">Admission</NavLink>
                      </li>
                      <li>
                        <a href="/assets/images/pdf/mark list.pdf">
                          Model Marklist
                        </a>
                      </li>
                      <li>
                        <a href="/assets/images/pdf/certificate.pdf">
                          Model Certificate
                        </a>
                      </li>
                      <li>
                        <NavLink to="/gallery">Gallery</NavLink>
                      </li>
                      <li>
                        <NavLink to="/atc_registration">ATC Registration</NavLink>
                      </li>
                      <li>
                        {/* <NavLink to="/gallery">Gallery</NavLink> */}
                        <a href="https://tssr-79f4a.web.app/">TASC Web App</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <a
                      style={{ color: "grey" }}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Examination
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/online-examination">Online Exam</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/register_form">Hall Ticket</NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/download_ht">
                          Download Hall Ticket
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/download_marksheet">
                          Download Result
                        </NavLink>
                      </li>
                      {/* <li>
                        <a href="/assets/images/pdf/hall ticket.pdf">Hall Ticket</a>
                      </li> */}
                      <li>
                        <NavLink to="/check-result">Check Result</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/shop">Shop Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/shop-single">Shop Details Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/cart-page">Shop Cart Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/search-page">Search Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/search-none">Search None</NavLink>
                      </li>
                      <li>
                        <NavLink to="/404">404</NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/coming_soon">Coming Soon</NavLink>
                      </li> */}
                    </ul>
                  </li>

                  <li>
                    <NavLink to="/contact_us" style={{ color: "grey" }}>
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* <Link to="#" className="login">
                <i className="icofont-user"></i> <span>Verify</span>{" "}
              </Link> */}
              <VerificationBtn color={"#0f317c"} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              {/* <Link to="/login" className="signup">
                <i className="icofont-users"></i> <span>Login</span>{" "}
              </Link> */}
              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

const TSSR_link = "/online-verification";

const TSCC_link = "/tscc-verfication";

const buttonStyle = {
  marginTop: 20,
  marginLeft: 20,
  marginRight: 20,
  padding: 30,
  borderRadius: 50,
  backgroundColor: "#0f317c",
  color: "#ffffff",
  fontSize: 30,
  fontWeight: "bolder",
};

const click_here_style = {
  fontSize: 15,
  fontWeight: "bold",
};

export const VerificationBtn = (props) => {
  return (
    <Popup
      trigger={
        <Link to="#" className="login">
          <i className="icofont-user" style={{ color: props.color }}></i>{" "}
          <span style={{ color: props.color, fontWeight: "bold" }}>Verify</span>{" "}
        </Link>
      }
      modal
      position="top center"
    >
      {(close) => (
        <center>
          <div
            style={{
              backgroundColor: "#8396C3",
              borderRadius: 50,
              borderColor: "#0f317c",
              borderWidth: 5,
              borderStyle: "solid",
              padding: 20,
            }}
          >
            <div
              className="close-button"
              onClick={() => {
                close();
              }}
            >
              <center>
                <NavLink to={{}}>X</NavLink>
              </center>
            </div>
            {/* <h2 style={{color:'#26c976'}}>Best education franchise provider<br/>and Certification Body</h2> */}
            <NavLink to={TSSR_link}>
              <img
                src="assets/images/popup_photos/logo.png"
                style={buttonStyle}
              />
              <br />
              <span style={click_here_style}>
                click here for TSSR Verification▲
              </span>
            </NavLink>
            <NavLink to={TSCC_link}>
              <img
                src="assets/images/popup_photos/logo2.png"
                style={buttonStyle}
              />
              <br />
              <span style={click_here_style}>
                click here for TSCC Verification▲
              </span>
            </NavLink>
          </div>
        </center>
      )}
    </Popup>
  );
};
