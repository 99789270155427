import { Fragment } from "react";

import { NavLink } from "react-router-dom";
import Footer from "../component/layout/footer";
import TsccHeader from "../component/layout/header-tscc";
import PageHeader from "../component/layout/pageheader";


const name = "Policy";
// const degi = "Master of Education Degree";
const desc = "Empower the Student to apply the new competencies in the workplace and to achieve their own life’s purpose. Excel in life and the workplace and add value to their communities.";
const subTitle = "Vision";
const infoDetails = "To frame the career of the Indian Youth’s and workers by marking technical investment of their knowledge and skills. To offer job opportunities beyond limitations.";
const subTitle2 = "Mission";
const infoDetails2 = "Upgrade skils to international standards through significant industry involvement and develop necessary frameworks for standards,curriculum and quality assurance.";
//const skillTitle = "Personal Language Skill";

const awardTitle = "Recognitions ";

// const memInfoLisst = [
//     {
//         leftText: 'Adress',
//         rightText: 'Suite 02 and 07 Melbourne, Australia',
//     },
//     {
//         leftText: 'Email',
//         rightText: 'emileelogan@gamil.com',
//     },
//     {
//         leftText: 'Phone',
//         rightText: '+021 548 736 982 ,01236985',
//     },
//     {
//         leftText: 'website',
//         rightText: 'www.adminedukon.com',
//     },


const memSocialList = [
    {
        iconName: 'icofont-twitter',
        className: 'twitter',
        siteLink: '#',
    },
    {
        iconName: 'icofont-instagram',
        className: 'instagram',
        siteLink: '#',
    },
    {
        iconName: 'icofont-basketball',
        className: 'basketball',
        siteLink: '#',
    },
    {
        iconName: 'icofont-vimeo',
        className: 'vimeo',
        siteLink: '#',
    },
    {
        iconName: 'icofont-behance',
        className: 'behance',
        siteLink: '#',
    },
]

const skillList = [
    {
        percentage: '80',
        text: 'English'
    },
    {
        percentage: '70',
        text: 'Hindi'
    },
    {
        percentage: '90',
        text: 'Bangla'
    },
    {
        percentage: '50',
        text: 'Arabic'
    },
]

const awardList = [
    {
        imgUrl: 'assets/images/instructor/single/icon/recognisations-1.png',
        imgAlt: 'instructor',
        text: 'Click >>',
        link: 'https://msme.gov.in/',
    },
    {
        imgUrl: 'assets/images/instructor/single/icon/recognisations-2.png',
        imgAlt: 'instructor',
        text: 'Click >>',
        link: 'https://msme.gov.in/',
    },
    {
    
     imgUrl: 'assets/images/instructor/single/icon/recognisations-3.png',
     imgAlt: 'instructor',
     text: 'Click >>',
     link: 'https://keralaregistration.gov.in/',
    
    }
]

const TsccPolicy = () => {
    return (
        <Fragment>
            <TsccHeader />
            <PageHeader title={'Policy - Vision - Mission'} curPage={'Emilee Logan'} />
            <section className="instructor-single-section padding-tb section-bg">
                <div className="container">
                    <div className="instructor-wrapper">
                        <div className="instructor-single-top">
                            <div className="instructor-single-item d-flex flex-wrap justify-content-between">
                                <div className="instructor-single-thumb">
                                    <img src="assets/images/instructor/single/01.jpg" alt="instructor" />
                                </div>
                                <div className="instructor-single-content">
                                    <h4 className="title">{name}</h4>
                                    {/* <p className="ins-dege">{degi}</p> */}
                                    {/* <Rating /> */}
                                    <p className="ins-desc">{desc}</p>
                                    <h4 className="subtitle">{subTitle}</h4>
                                    <p className="ins-desc">{infoDetails}</p>

                                    <h4 className="subtitle2">{subTitle2}</h4>

                                    <p className="ins-desc">{infoDetails2}</p>
                                    <div style={{backgroundColor:"#26c976", width:100}}>
                                        <NavLink to="/assets/images/tscc/Skill Certification.pdf"><h6>Click here</h6></NavLink>
                                    </div>
                                     {/* <ul className="lab-ul">
                                        {memInfoLisst.map((val, i) => (
                                            <li className="d-flex flex-wrap justify-content-start" key={i}>
                                                <span className="list-name">{val.leftText}</span>
                                                <span className="list-attr">{val.rightText}</span>
                                            </li>
                                        ))}
                                        <li className="d-flex flex-wrap justify-content-start">
                                            <span className="list-name">Follow Us</span>
                                            <ul className="lab-ul list-attr d-flex flex-wrap justify-content-start">
                                                {memSocialList.map((val, i) => (
                                                    <li key={i}>
                                                        <a className={val.className} href={val.siteLink}><i className={val.iconName}></i></NavLink>
                                                    </li>
                                                ))}
                                            </ul> 
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>



                        
                        <div className="instructor-single-bottom d-flex flex-wrap mt-4">
                            {/* <div className="col-xl-6 pb-5 pb-xl-0 d-flex flex-wrap justify-content-lg-start justify-content-between">
                                <h4 className="subtitle">{skillTitle}</h4>
                                {skillList.map((val, i) => (
                                    <div className="text-center skill-item" key={i}>
                                        <div className="skill-thumb">
                                            <Progress strokeWidth={8} percentage={val.percentage} />
                                        </div>
                                        <p>{val.text}</p>
                                    </div>
                                ))}
                            </div> */}
                            <div className="col-xl-8 d-flex flex-wrap justify-content-lg-start justify-content-between" style={{padding : 20}}>
                                <h3 className="subtitle">{awardTitle}</h3>
                                {awardList.map((val, i) => (
                                    <div className="skill-item text-center" key={i}>
                                        <div className="skill-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="category-content">
                                                <NavLink to={val.link}> <h4>{val.text}</h4></NavLink>
                                                </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    );
}
 
export default TsccPolicy;