import React, { Fragment, Component, useState } from "react";
import Footer from "../layout/footer";
import { db, tssrCol } from "../../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useRef } from "react";
import Header from "../layout/header";

const conSubTitle = "TSSR";
const conTitle = "ATC Registration";
const btnText = "submit";

const atcRequestCollectionName = "Atc_Requests";

const AtcRegRequestPage = () => {
  const centre_head = useRef();
  const centre_name = useRef();
  const phone_no = useRef();
  const place = useRef();
  const city = useRef();
  const pincode = useRef();
  const selectedDistrict = useRef();

  const handleDropDownListSelection = (event) => {
    selectedDistrict.current = event.target.value;
  };

  const handleSubmit = (e) => {
    addDoc(collection(db, atcRequestCollectionName), {})
      .then((e) => {
        setDoc(doc(db, atcRequestCollectionName, e.id), {
          id: e.id,
          centre_head: centre_head.current,
          centre_name: centre_name.current,
          phone_no: phone_no.current,
          place: place.current,
          city: city.current,
          district: selectedDistrict.current,
          pincode: pincode.current,
          timestamp: Date.now().toString(),
        }).then(() => {
          alert("Your request is submitted.");
          // resettoempty();
          // document.getElementById("tssr-form").reset();
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const resettoempty = () => {
    centre_head.current = "";
    centre_name.current = "";
    phone_no.current = "";
    place.current = "";
    city.current = "";
    pincode.current = "";
  };

  return (
    <Fragment>
      <Header />
      <div className="map-address-section padding-tb section-bg"></div>
      <div className="contact-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            <form className="contact-form" id="tssr-form">
              {/* ///////////////////////// */}

              {/* Centre Head  */}
              <div className="form-group">
                <label className="admin-page-label">Centre Head</label>
                <input
                  value={centre_head.current}
                  onChange={(e) => (centre_head.current = e.target.value)}
                  type="text"
                  placeholder="Centre Head *"
                />
              </div>
              {/* Centre Name */}
              <div className="form-group">
                <label className="admin-page-label">Centre Name</label>
                <input
                  value={centre_name.current}
                  onChange={(e) => (centre_name.current = e.target.value)}
                  type="text"
                  placeholder="Centre Name *"
                />
              </div>
              {/* Phone No */}
              <div className="form-group">
                <label className="admin-page-label">Phone No</label>
                <input
                  value={phone_no.current}
                  onChange={(e) => (phone_no.current = e.target.value)}
                  type="text"
                  placeholder="Phone No *"
                />
              </div>
              {/* Place */}
              <div className="form-group">
                <label className="admin-page-label">Place</label>
                <input
                  value={place.current}
                  onChange={(e) => (place.current = e.target.value)}
                  type="text"
                  placeholder="Place"
                />
              </div>
              {/* City */}
              <div className="form-group">
                <label className="admin-page-label">City</label>
                <input
                  value={city.current}
                  onChange={(e) => (city.current = e.target.value)}
                  type="text"
                  placeholder="City"
                />
              </div>
              {/* District */}
              <div className="form-group">
                <label htmlFor="dropdown">Select an option:</label>
                <select
                  id="dropdown"
                  value={selectedDistrict.current}
                  onChange={handleDropDownListSelection}
                >
                  <option value="Select district">Select district</option>
                  <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                  <option value="Kollam">Kollam</option>
                  <option value="Pathanamthitta">Pathanamthitta</option>
                  <option value="Alappuzha">Alappuzha</option>
                  <option value="Kottayam">Kottayam</option>
                  <option value="Idukki">Idukki</option>
                  <option value="Ernakulam">Ernakulam</option>
                  <option value="Thrissur">Thrissur</option>
                  <option value="Palakkad">Palakkad</option>
                  <option value="Malappuram">Malappuram</option>
                  <option value="Kozhikode">Kozhikode</option>
                  <option value="Wayanad">Wayanad</option>
                  <option value="Kannur">Kannur</option>
                  <option value="Kasaragod">Kasaragod</option>
                </select>

                {selectedDistrict.current && (
                  <p>You selected: {selectedDistrict.current}</p>
                )}
              </div>
              {/* Pincode */}
              <div className="form-group">
                <label className="admin-page-label">Pincode</label>
                <input
                  value={pincode.current}
                  onChange={(e) => (pincode.current = e.target.value)}
                  type="text"
                  placeholder="Pincode"
                />
              </div>

              {/* submit button  */}
              <div className=" w-100 text-center">
                {/* <button className="lab-btn" onSubmit={handleSubmit}>
                  <span>{btnText}</span>
                </button> */}
                <input
                  type={"button"}
                  value={"submit"}
                  onClick={handleSubmit}
                  className="file-upload-btn"
                />
              </div>
              {/* ///////////////////////////////// */}
            </form>
            {/* <input type={'button'} value={'test'} onClick={test}/> */}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default AtcRegRequestPage;
