import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const sponsorList = [
  {
    imgUrl: "assets/images/sponsor/TssrCouncil.png",
    imgAlt: "TSSR Council",
  },

  {
    imgUrl: "assets/images/sponsor/EGAC.png",
    imgAlt: "EGAC",
  },
  {
    imgUrl: "assets/images/sponsor/EIAC-Logo.png",
    imgAlt: "EIAC",
  },
  {
    imgUrl: "assets/images/sponsor/IAF.png",
    imgAlt: "IAF",
  },
  {
    imgUrl: "assets/images/sponsor/IncomeTaxDept.png",
    imgAlt: "Income Tax Dept",
  },
  {
    imgUrl: "assets/images/sponsor/IsoCertifiedCompany.png",
    imgAlt: "ISO Certified",
  },
  {
    imgUrl: "assets/images/sponsor/MakeInIndia.png",
    imgAlt: "Make In India",
  },
  {
    imgUrl: "assets/images/sponsor/Opastaja.png",
    imgAlt: "Opastaja",
  },
];

const Sponsor = () => {
  return (
    <div className="sponsor-section section-bg">
      <div className="container">
        <div className="section-wrapper">
          <div className="sponsor-slider">
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              loop={"true"}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                0: {
                  autoplay:true,
                  slidesPerGroupAuto:1,
                  width: 0,
                  slidesPerView: 2,
                },
                768: {
                  width: 768,
                  slidesPerView: 3,
                },
                1200: {
                  width: 1200,
                  slidesPerView: 5.5,
                },
                320: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4,
      spaceBetween: 40
    }
              }}
            >
              {sponsorList.map((val, i) => (
                <SwiperSlide key={i}  style={{alignContent:'center'}}>
                  <div className="sponsor-iten">
                    <div className="sponsor-thumb" >
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsor;
