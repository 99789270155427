
import { Link } from "react-router-dom";


const PageHeader = ({title, curPage}) => {
    return (
        <div className="pageheader-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="pageheader-content text-center">
                            <h2>{title}</h2>
                            <div className="about-subtitle1">Get Franchise for starting a course at low investment to provide quality education.</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PageHeader;