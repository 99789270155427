import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import Tilt from "react-parallax-tilt";
import Popup from "reactjs-popup";
import { Link, NavLink } from "react-router-dom";
import { VerificationBtn } from "../layout/header";
//import 'src/assets/css/popup.css';

const subTitle = "Online education";
const title = (
  <h2 className="title">
    <span className="d-lg-block">We Build Your</span>Best Qualities
    <span className="d-lg-block"></span>
  </h2>
);
const desc =
  "Take the test of efficiency to learn \naptitudes required by a genius\nand prove you are";

///The link on popup buttons

const TSSR_link = "#";

const TSCC_link = "tscc-home";

//////////////////////

const catagoryList = [
  {
    name: "Figma",
    link: "#",
  },
  {
    name: "Adobe XD",
    link: "#",
  },
  {
    name: "illustration",
    link: "#",
  },
  {
    name: "Photoshop",
    link: "#",
  },
];

const shapeList = [
  {
    name: "92% Assessment Projects",
    name: "16M Students Happy",
    link: "#",
    className: "ccl-shape shape-1",
  },
  {
    name: "50k Testing Centres",
    name: "130K+ Total Courses",
    link: "#",
    className: "ccl-shape shape-2",
  },
  {
    name: "83% Certified Members",
    name: "89% Successful Students",
    link: "#",
    className: "ccl-shape shape-3",
  },
  {
    name: "Specialized Standard Syllabus",
    name: "23M+ Learners",
    link: "#",
    className: "ccl-shape shape-4",
  },
  {
    name: "1400+ Courses",
    name: "36+ Languages",
    link: "#",
    className: "ccl-shape shape-5",
  },
];

const options = {
  fullScreen: {
    enable: true,
    zIndex: -1,
  },
  particles: {
    number: {
      value: 200,
      limit: 300,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: "#000000",
    },
    shape: {
      type: "circle",
      stroke: {
        width: 0,
        color: "#000000",
      },
      polygon: {
        nb_sides: 5,
      },
      image: {
        src: "images/github.svg",
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.5,
      random: true,
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0.5,
        sync: false,
      },
    },
    size: {
      value: 30,
      random: true,
      anim: {
        enable: true,
        speed: 10,
        size_min: 10,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 100,
      color: "#ffffff",
      opacity: 1,
      width: 1,
    },
    move: {
      enable: true,
      speed: 1,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onHover: {
        enable: true,
        mode: "bubble",
        parallax: {
          enable: false,
          force: 60,
          smooth: 10,
        },
      },
      onClick: {
        enable: true,
        mode: "push",
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        lineLinked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 100,
        duration: 2,
        opacity: 1,
        speed: 2,
      },
      repulse: {
        distance: 200,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  backgroundMask: {
    enable: true,
    cover: {
      color: {
        value: {
          r: 255,
          g: 255,
          b: 255,
        },
      },
    },
  },
  retina_detect: true,
  fps_limit: 60,
  background: {
    image: "url('https://particles.js.org/images/background3.jpg')",
  },
};

const Banner = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <section className="banner-section">
      <div className="container ">
        <div className="section-wrapper">
          <Particles options={options} init={particlesInit} />

          <div className="row align-items-center">
            <div className="col-xxl-5 col-xl-6 col-lg-10">
              <div className="banner-content" style={{ paddingBottom: 80 }}>
                {/* <h6 className="subtitle text-uppercase fw-medium">
                  {subTitle}
                </h6> */}
                {title}
                <p className="desc">{desc}</p>

                {/* <form ><Popup trigger={<button> Get Admission</button>} modal position="right center">
    <div>Popup content here !!</div>
  </Popup></form> */}
                <GetAdmission />
                <br />
                <br />
                <div
                  style={{
                    backgroundColor: "#26c976",
                    height: 50,
                    width: 300,
                    paddingTop: 10,
                  }}
                >
                  <center>
                    <VerificationBtn color={"#ffffff"} />
                  </center>
                </div>

                {/* <form>
                  <button type="submit"> Get Admission </button>
                </form> */}
                {/* <div className="banner-catagory d-flex flex-wrap">
                  <p>Most Popular : </p>
                  <ul className="lab-ul d-flex flex-wrap">
                    {catagoryList.map((val, i) => (
                      <li key={i}>
                        <NavLink to={val.link}>{val.name}</NavLink>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6">
              <Tilt
                className="track-on-window"
                perspective={700}
                glareEnable={false}
                scale={1.02}
                trackOnWindow={true}
              >
                <div className="banner-thumb ">
                  <img src="assets/images/banner/logo1.png" alt="img" />
                </div>
              </Tilt>
            </div>
          </div>
        </div>

        {/* <div className="all-shapes"></div> */}
        <div className="cbs-content-list d-none">
          <ul className="lab-ul">
            {shapeList.map((val, i) => (
              <li className={val.className} key={i}>
                <NavLink to={val.link}>{val.name}</NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Banner;

const buttonStyle = {
  marginTop: 20,
  marginLeft: 20,
  marginRight: 20,
  padding: 30,
  borderRadius: 50,
  backgroundColor: "#0f317c",
  color: "#ffffff",
  fontSize: 30,
  fontWeight: "bolder",
};

const click_here_style = {
  fontSize: 15,
  fontWeight: "bold",
};

const GetAdmission = () => {
  return (
    <Popup
      trigger={
        <NavLink to={{}}>
          <div
            style={{
              backgroundColor: "#0f317c",
              height: 50,
              width: 300,
              paddingTop: 10,
            }}
          >
            <center>
              <span
                className="align-items-center"
                style={{ fontSize: 20, fontWeight: "bold", color: "#ffffff" }}
              >
                Get Admission
              </span>
            </center>
          </div>
        </NavLink>
      }
      modal
      position="top center"
    >
      {(close) => (
        <center>
          <div
            style={{
              backgroundColor: "#8396C3",
              borderRadius: 50,
              borderColor: "#0f317c",
              borderWidth: 5,
              borderStyle: "solid",
              padding: 20,
            }}
          >
            <div
              className="close-button"
              onClick={() => {
                close();
              }}
            >
              <center>
                <NavLink to={{}}>X</NavLink>
              </center>
            </div>
            {/* <h2 style={{color:'#26c976'}}>Best education franchise provider<br/>and Certification Body</h2> */}
            <NavLink to={TSSR_link}>
              <img
                src="assets/images/popup_photos/logo.png"
                style={buttonStyle}
              />
              <br />
              <span style={click_here_style}>click here for TSSR ▲</span>
            </NavLink>
            <NavLink to={TSCC_link}>
              <img
                src="assets/images/popup_photos/logo2.png"
                style={buttonStyle}
              />
              <br />
              <span style={click_here_style}>click here for TSCC ▲</span>
            </NavLink>
          </div>
        </center>
      )}
    </Popup>
  );
};
