import { ref, uploadBytesResumable } from "firebase/storage";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { storage } from "../../firebase";
import Header from "../layout/admin_header";

const AdminGalleryUpload = () => {
  const [file, setFile] = useState("");
  const [percentt, setPercent] = useState(0);
  var i = 0;

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleSubmit = (e) => {
    if (!file) {
      alert("Please upload an image first!");
      return;
    }
    const now = new Date();
    const dateTimeString = `${now.getFullYear()}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")} ${now
      .getHours()
      .toString()
      .padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}:${now
      .getSeconds()
      .toString()
      .padStart(2, "0")}.${now.getMilliseconds().toString().padStart(3, "0")}`;

    const storageRef = ref(
      storage,
      "gallery/" + encodeURIComponent(dateTimeString)
    ); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.    console.log(loading);
    try {
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          ); // update progress
          setPercent(percent);
          console.log(percentt);
        },
        (err) => alert(err),
        () => {
          Swal.fire({
            title: 'Uploaded',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
        }
      );
    } catch (err) {
      alert(err);
    } finally {
    }
  };

  return (
    <Fragment>
      <Header />
      <div style={{ padding: 100 }}>
        <div style={{ height: 100 }}></div>
        <center>
          <h1>Gallery Upload</h1>
          <div className="form-group w-100 text-center">
            <input type="file" accept="/image/*" onChange={handleChange} />
            <div style={{ height: 50 }}></div>
            <input
              type={"button"}
              onClick={handleSubmit}
              value={i == 1 ? "loading" : "submit"}
              className="lab-btn"
            />
            <div style={{ height: 50 }}></div>
            <div style={{ paddingLeft: 200, paddingRight: 200 }}>
              <Link to={"/67anel7Kc7anel7KcCKSkQ63NKS5"}>
                <input
                  type={"button"}
                  value="Show Gallery"
                  className="lab-btn"
                />
              </Link>
            </div>
          </div>
        </center>
      </div>
    </Fragment>
  );
};

export default AdminGalleryUpload;
