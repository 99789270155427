import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Fragment, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { auth, db } from "../../firebase";
import Login from "../../page/login";
import Header from "../layout/admin_header";

const StudyCentreTable = () => {
  const colRef = collection(db, "Franchise");

  const [datas, setDatas] = useState([]);
  const [lastDoc, setLastDoc] = useState();
  const [ver, setState] = useState(false);
  var dts = [];
  var dataList = [];
  const searchWord = useRef();

  useEffect(() => {
    fetch();
  }, []);

  const fetchMore = () => {
    const q = query(
      colRef,
      orderBy("centre_name"),
      startAfter(lastDoc),
      limit(10)
    );
    getDocs(q).then((e) => {
      const dts = e.docs.map((dt) => dt.data());
      const lastDoc = e.docs[e.docs.length - 1];
      setDatas((datas) => [...datas, ...dts]);
      setLastDoc(lastDoc);
    });
  };

  const fetch = () => {
    setDatas([]);
    setLastDoc();
    dts = [];
    const q = query(colRef, orderBy("centre_name"), limit(5));
    getDocs(q).then((e) => {
      const dts = e.docs.map((dt) => dt.data());
      const lastDoc = e.docs[e.docs.length - 1];
      setDatas((datas) => [...datas, ...dts]);
      setLastDoc(lastDoc);
    });
  };

  if (datas.length === 0) {
    return <h1>Loading...</h1>;
  }

  const search = (e) => {
    if (searchWord.current == "") {
      fetch();
    } else {
      dataList = [];
      const d = collection(db, "Franchise");
      var q = query(
        d,
        where("atc", ">=", searchWord.current),
        where("ATC_code", "<=", searchWord.current + "\uf8ff")
      );

      getDocs(q)
        .then((e) => {
          //#console.log("name search");
          e.docs.forEach((e) => {
            dataList.push(e.data());
          });
        })
        .then(() => {
          //#console.log("id search");
          q = query(
            d,
            where("centre_name", ">=", searchWord.current),
            where("centre_name", "<=", searchWord.current + "\uf8ff")
          );
          getDocs(q).then((e) => {
            e.docs.forEach((e) => {
              dataList.push(e.data());
            });
            setDatas(dataList);
          });
        });
    }
  };

  const delete_undefined = (e) => {
    Swal.fire({
      title: "Do you want to Delete all data in this table?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((response) => {
      if (response.isConfirmed) {
        const batch = writeBatch(db);
        var idsToDelete = [];
        const d = collection(db, "Franchise");

        const q = query(d, orderBy("centre_name"));

        getDocs(q)
          .then((e) => {
            e.docs.forEach((e) => {
              idsToDelete.push(e.data().id);
            });
          })
          .then(async (e) => {
            idsToDelete.forEach((id) => {
              const docRef = doc(db, "Franchise", id);
              batch.delete(docRef);
            });
            await batch.commit().then((e) => {
              alert("Deleted all data!!");
              fetch();
            });
          });
      } else {
        alert("Deletion Cancelled");
      }
    });
  };

  const deleteId = (e) => {
    const ref = doc(db, "Franchise", e);
    if (window.confirm("Are you sure to delete ID ?")) {
      deleteDoc(ref);
      alert("Deleted");
    }
    fetch();
  };

  onAuthStateChanged(auth, (user) => {
    if (user) setState(true);
    // else setState(false);
  });

  if (ver == true)
    return (
      <Fragment>
        <Header />
        <div style={{ height: 50 }}></div>
        <center>
          <h2 className="title">Study Centre Table</h2>
        </center>
        <div style={{ padding: 50 }}>
          <Table striped="columns">
            <thead style={{ backgroundColor: "#77a8a8" }}>
              <tr>
                <th>#</th>
                <th>Centre Name</th>
                <th>ATC Code</th>
                <th className="table-btn-layout">
                  <input
                    style={{ borderRadius: 100, marginRight: 10 }}
                    type={"search"}
                    placeholder="Search"
                    id="search"
                    defaultValue={searchWord.current}
                    onChange={(e) => (searchWord.current = e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") search();
                    }}
                  />
                  <input
                    style={{ backgroundColor: "red", fontWeight: "bolder" }}
                    type={"button"}
                    value="Delete All"
                    className="table-tbn"
                    onClick={delete_undefined}
                  />
                </th>
                {/* <ReloadIcon color="#ffffff"/> */}
              </tr>
            </thead>

            <tbody className="table-row">
              {datas.map((val, i) => (
                <tr key={i + 1}>
                  {/* <ShowData data={val.id} show={i + 1} />} */}
                  <td>{i + 1}</td>
                  <td>{val.centre_name}</td>
                  <td>{val.ATC_code}</td>

                  <td style={{ width: 400 }}>
                    <div className="table-btn-layout">
                      {/* <EditBtn data={val.id} fun={fetch} /> */}
                      <Link to={`/XdN6i67KSkbJZOpXQLdvnWCM98dfid/${val.id}`}>
                        <input
                          className="table-tbn"
                          style={{
                            backgroundColor: "lightblue",
                            color: "white",
                            fontWeight: "bold",
                          }}
                          type={"button"}
                          value="Edit"
                        />
                      </Link>
                      <input
                        className="table-tbn"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        type={"button"}
                        value="Delete"
                        onClick={() => {
                          deleteId(val.id);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <input type="button" value="Load More" onClick={fetchMore} />
        </div>
      </Fragment>
    );
  else return <Login />;
};

export default StudyCentreTable;

export const EditStudyCentre = () => {
  let params = useParams();
  const id = decodeURIComponent(params.id);

  const ATC_code = useRef();
  const centre_head = useRef();
  const centre_name = useRef();
  const district = useRef();
  const place = useRef();
  const renewal = useRef();
  const [ver, setState] = useState(false);

  // const [ver, setState] = useState(false);

  if (ATC_code.current == "") {
    return <h1>Loading</h1>;
  }

  onAuthStateChanged(auth, (user) => {
    if (user) setState(true);
    // else setState(false);
  });

  const docRef = doc(db, "Franchise", id);
  getDoc(docRef).then((e) => {
    ATC_code.current = e.data().atc;
    centre_head.current = e.data().centre_head;
    centre_name.current = e.data().centre_name;
    district.current = e.data().district;
    place.current = e.data().place;
    renewal.current = e.data().renewal;
    document.getElementById("atc").value = e.data().atc;
    document.getElementById("centre_head").value = e.data().centre_head;
    document.getElementById("centre_name").value = e.data().centre_name;
    document.getElementById("district").value = e.data().district;
    document.getElementById("place").value = e.data().place;
    document.getElementById("renewal").value = e.data().renewal;
  });

  if (ver == true)
    return (
      <div style={{ padding: 100 }}>
        <div style={{ height: 100 }}></div>
        <form className="contact-form" id="tssc-form">
          {/* ATC code */}
          <div className="form-group">
            <label className="admin-page-label">ATC code</label>
            <input
              id="atc"
              defaultValue={ATC_code.current}
              onChange={(e) => (ATC_code.current = e.target.value)}
              type="text"
              name="atc"
              placeholder="ATC code"
            />
          </div>
          {/* Centre Head */}
          <div className="form-group">
            <label className="admin-page-label">Centre Head</label>
            <input
              id="centre_head"
              defaultValue={centre_head.current}
              onChange={(e) => (centre_head.current = e.target.value)}
              type="text"
              name="centre_head"
              placeholder="Centre Head"
            />
          </div>
          {/* Centre Name */}
          <div className="form-group">
            <label className="admin-page-label">Centre Name</label>
            <input
              id="centre_name"
              defaultValue={centre_name.current}
              onChange={(e) => (centre_name.current = e.target.value)}
              type="text"
              name="centre_name"
              placeholder="Centre Name"
            />
          </div>
          {/* District */}
          <div className="form-group">
            <label className="admin-page-label">District</label>
            <input
              id="district"
              defaultValue={district.current}
              onChange={(e) => (district.current = e.target.value)}
              type="text"
              name="district"
              placeholder="District"
            />
          </div>
          {/* Placee */}
          <div className="form-group">
            <label className="admin-page-label">Place</label>
            <input
              id="place"
              defaultValue={place.current}
              onChange={(e) => (place.current = e.target.value)}
              type="text"
              name="place"
              placeholder="Place"
            />
          </div>
          {/* Renewal */}
          <div className="form-group">
            <label className="admin-page-label">Renewal</label>
            <input
              id="renewal"
              defaultValue={renewal.current}
              onChange={(e) => {
                renewal.current = e.target.value;
              }}
              type="text"
              name="renewal"
              placeholder="Renewal"
            />
          </div>
          <div className=" w-100 text-center">
            <input
              type={"button"}
              value={"submit"}
              onClick={async () => {
                await updateDoc(doc(db, "Franchise", id), {
                  ATC_code: ATC_code.current,
                  centre_head: centre_head.current,
                  centre_name: centre_name.current,
                  district: district.current,
                  place: place.current,
                  renewal: renewal.current,
                }).then(() => {
                  alert("Updated");
                });
              }}
              className="file-upload-btn"
            />
          </div>
        </form>
        <div style={{ height: 100 }}></div>
      </div>
    );
  else return <Login />;
};
