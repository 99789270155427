


const subTitle = "About TSSR";
const title = "Educate, Innovate, Elevate.";
const desc = "Technical Study and Skill Research (TSSR) Council is Providing Franchise to institutions which conduct short term courses and skilled training courses in Technical and other disciplines. We have franchise to more than 1400+ courses and the students who complete the trainining in the institute which have our franchise will receive iso and other authorities recognized certificates.";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon ',
        title: 'Our Mission',
        // desc: 'The mission of TSSR Council is to advance knowledge and educate in science, teaching, technology, and other areas of education that will best serve the nation and the world.',
        desc:"TSSR Council is to cultivate and disseminate knowledge in science, education, technology, and related fields to enhance the well-being of society and the global community.",
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Our Vision',
        // desc: 'TSSR Council embrace Value making through economic,social, and environmental development targeted to an increasingly global and multicultural world.',
        desc:"TSSR Council aspires to lead transformative educational initiatives that promote economic, social, and environmental progress, fostering a diverse and interconnected world.",
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: ' Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const About = () => {
    return (
        <div className="about-section">
            <div className="container">
                <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
                    <div className="col">
                        <div className="about-right padding-tb">
                            <div className="section-header" style={{backgroundColor:'#fff9f1',padding:20}}>
                                <span className="subtitle">{subTitle}</span>
                                <h2 className="title">{title}</h2>
                                <p>{desc}</p>
                            </div>
                            <div className="section-wrapper" style={{backgroundColor:'#fff9f1',padding:20}}>
                                <ul className="lab-ul">
                                    {aboutList.map((val, i) => (
                                        <li key={i}>
                                            <div className="sr-left">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="sr-right">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div> 
                    <div className="col">
                        <div className="about-left">
                            <div className="about-thumb">
                                <img src="assets/images/about/01.png" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default About;