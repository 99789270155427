import { deleteObject, getDownloadURL, list, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { storage } from "../../firebase";

const AdminGalleryShow = () => {
  const [picUrls, setPhotoUrls] = useState([]);

  useEffect(() => {
    const getPhotos = async () => {
      const folderRef = ref(storage, "gallery/");
      const photoList = await list(folderRef);
      const photoUrls = await Promise.all(
        photoList.items.map((itemRef) => getDownloadURL(itemRef))
      );
      // console.log(photoUrls);
      setPhotoUrls(photoUrls);
    };
    getPhotos();
  }, []);

  const showOptions=(val)=>{
    Swal.fire({
        showDenyButton:true,
        icon: "question",
        confirmButtonText: "Show",
        denyButtonText:'Delete',
      }).then((response)=>{
        if(response.isConfirmed)window.location.href=val
        else if(response.isDenied)deletePhoto(val)
      })
  }

  const deletePhoto = (val) => {
    Swal.fire({
      title: "Do you want to Delete this photo?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((response) => {
      if (response.isConfirmed) {
        var name = decodeURIComponent(val);
        // var path = name.substring(84, 113); tssr 1
        var path = name.substring(78, 107); //tssr 2
        console.log(path);
        deleteObject(ref(storage, "gallery/" + path)).then(() => {
            console.log('deleted');
            Swal.fire({
                title: 'Deleted',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
              })
              window.location.reload();
        });
      } else {
        Swal.fire({
            title: 'Deletion Cancelled',
            icon: 'info',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
      }
    });
  };

  return (
    <div className="blog-section padding-tb section-bg">
      <div className="container">
        <div className="section-wrapper">
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
            <h2>{picUrls.length===0?'No Photos':''}</h2>
            {picUrls.map((val, i) => (
              <div className="col" key={val}>
                <div className="post-item">
                  <div className="post-inner">
                    <div className="post-thumb">
                      <a >
                        {" "}
                        <img
                          src={`${val}`}
                          alt={`${"image"}`}
                          onClick={()=>{
                            showOptions(val)
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminGalleryShow;

// https://firebasestorage.googleapis.com/v0/b/tssrcouncil-d806d.appspot.com/o/gallery%2F2023-03-19%252019%253A11%253A54.398?alt=media&token=d04e601f-f0b5-4674-92b7-fe53e1a5df6b
// https://firebasestorage.googleapis.com/v0/b/tssr2-90b12.appspot.com/o/gallery%2F2023-03-19%252022%253A41%253A17.940?alt=media&token=d082fea1-484a-469e-96c9-b4dcad6714e2