// import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";
// import { useCallback } from "react";
import Tilt from "react-parallax-tilt";
import Footer from "../layout/footer";
import Header from "../layout/header-tscc";
import { Link, NavLink } from "react-router-dom";
//import Popup from "reactjs-popup";
//import 'src/assets/css/popup.css';

const subTitle = "Online education";
const title = (
  <h2 className="title">
    <span className="d-lg-block">INDIA'S LEADING & </span>LARGEST CERTIFICATION
    BODY
    <span className="d-lg-block"></span>
  </h2>
);
const desc =
  "Take the test of efficiency to learn \naptitudes required by a genius\nand prove you are";

const catagoryList = [
  {
    name: "Figma",
    link: "#",
  },
  {
    name: "Adobe XD",
    link: "#",
  },
  {
    name: "illustration",
    link: "#",
  },
  {
    name: "Photoshop",
    link: "#",
  },
];

const shapeList = [
  {
    name: "92% Assessment Projects",
    name: "16M Students Happy",
    link: "#",
    className: "ccl-shape shape-1",
  },
  {
    name: "50k Testing Centres",
    name: "130K+ Total Courses",
    link: "#",
    className: "ccl-shape shape-2",
  },
  {
    name: "83% Certified Members",
    name: "89% Successful Students",
    link: "#",
    className: "ccl-shape shape-3",
  },
  {
    name: "Specialized Standard Syllabus",
    name: "23M+ Learners",
    link: "#",
    className: "ccl-shape shape-4",
  },
  {
    name: "1400+ Courses",
    name: "36+ Languages",
    link: "#",
    className: "ccl-shape shape-5",
  },
];

const TsccBanner = () => {
  return (
    <section >
      
      <Header />
      <div style={{height:200}}></div>
      <div></div>
      <div className="container ">
        <div className="section-wrapper">
          <div className="row align-items-center">
            <div className="col-xxl-5 col-xl-6 col-lg-10">
              <div
                className="banner-content mouse-c"
                style={{ paddingBottom: 80 }}
              >
                {/* <h6 className="subtitle text-uppercase fw-medium">
                  {subTitle}
                </h6> */}
                {title}
                <p className="desc">{desc}</p>
                <form>
                  <button> Get Admission</button>
                   
                </form>
                {/* <form>
                  <button type="submit"> Get Admission </button>
                </form> */}
                {/* <div className="banner-catagory d-flex flex-wrap">
                  <p>Most Popular : </p>
                  <ul className="lab-ul d-flex flex-wrap">
                    {catagoryList.map((val, i) => (
                      <li key={i}>
                        <NavLink to={val.link}>{val.name}</NavLink>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6">
              <Tilt
                className="track-on-window"
                perspective={700}
                glareEnable={true}
                glareMaxOpacity={0.75}
                glarePosition="all"
                scale={1.02}
                trackOnWindow={true}
              >
                <div className="banner-thumb ">
                  <img src="assets/images/tsscp/tscc.png" alt="img" style={{height:480,width:480}} />
                </div>
              </Tilt>
            </div>
          </div>
        </div>

        {/* <div className="all-shapes"></div> */}
        <div className="cbs-content-list d-none">
          <ul className="lab-ul">
            {shapeList.map((val, i) => (
              <li className={val.className} key={i}>
                <NavLink to={val.link}>{val.name}</NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div style={{height:50}}></div>
      <Footer />
    </section>
  );
};

export default TsccBanner;
