


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.2275990948147!2d90.38698831543141!3d23.739261895117753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b85c740f17d1%3A0xdd3daab8c90eb11f!2sCodexCoder!5e0!3m2!1sen!2sbd!4v1607313671993!5m2!1sen!2sbd"></iframe> */}
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3909.9940147166003!2d75.97189811400398!3d11.480356991846214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba66e95cbda5fc3%3A0xce124da1d2b708f6!2sPanchayath%20Bazar%20Bus%20Stop!5e0!3m2!1sen!2sin!4v1666498699046!5m2!1sen!2sin" ></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3910.2294792648995!2d75.968773!3d11.463355400000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba66ec9d1fd1379%3A0x4cc6f66f5155f0cd!2sState%20Bank%20of%20India%20EANGAPUZHA!5e0!3m2!1sen!2sin!4v1668705370480!5m2!1sen!2sin" ></iframe>
           
            </div>
        </div>
    );
}
 
export default GoogleMap;