import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import { NavLink } from "react-router-dom";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GroupSelect from "../component/sidebar/group-select";
//import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
const btnText = "Browse All Categories";




const courseList = [
    {
        imgUrl: '/assets/images/gallery/5.jpg',
        imgAlt: '',
        //price: '$30',
        //cate: 'Medicine',
        //reviewCount: '03 reviews',
        title: 'Teachers Training Course',
        //totalLeson: '18x Lesson',
        //schdule: 'Online Class',
        //authorImgUrl: 'assets/images/course/author/01.jpg',
        //authorImgAlt: 'course author rajibraj91 rajibraj',
        //authorName: 'William Smith',
        btnText: 'Read More',

    },
    {
        imgUrl: '/assets/images/gallery/2.jpeg',
        imgAlt: 'course ',
        //price: '$30',
        //cate: 'Adobe XD',
        //reviewCount: '03 reviews',
        title: 'Human Resources Development',
        //totalLeson: '18x Lesson',
        //schdule: 'Online Class',
        //authorImgUrl: 'assets/images/course/author/02.jpg',
        //authorImgAlt: 'course author rajibraj91 rajibraj',
        //authorName: 'Lora Smith',
        btnText: 'Read More',
    },
    {
        imgUrl: 'assets/images/course/agri.png',
        imgAlt: 'course ',
        //price: '$30',
        //cate: 'Adobe XD',
        //reviewCount: '03 reviews',
        title: 'Indian Chiropractic Course',
        //totalLeson: '18x Lesson',
        //schdule: 'Online Class',
        //authorImgUrl: 'assets/images/course/author/03.jpg',
        //authorImgAlt: 'course author rajibraj91 rajibraj',
        //authorName: 'Robot Smith',
        btnText: 'Read More',
    },
    {
        imgUrl: 'assets/images/course/skill.jpg',
        imgAlt: 'course ',
        //price: '$30',
        //cate: 'Adobe XD',
        //reviewCount: '03 reviews',
        title: 'Technical & Skill Courses',
        //totalLeson: '18x Lesson',
        //schdule: 'Online Class',
        //authorImgUrl: 'assets/images/course/author/04.jpg',
        //authorImgAlt: 'course author rajibraj91 rajibraj',
        //authorName: 'Zinat Zaara',
        btnText: 'Read More',
    },
    {
        imgUrl: 'assets/images/course/Ayu.png',
        imgAlt: 'course ',
        //price: '$30',
        //cate: 'Adobe XD',
        //reviewCount: '03 reviews',
        title: 'Ayurveda & Siddha Course',
        //totalLeson: '18x Lesson',
        //schdule: 'Online Class',
        //authorImgUrl: 'assets/images/course/author/05.jpg',
        //authorImgAlt: 'course author rajibraj91 rajibraj',
        //authorName: 'Rajib Raj',
        btnText: 'Read More',
    },
    {
        imgUrl: 'assets/images/course/01.jpg',
        imgAlt: 'course ',
        //price: '$30',
        //cate: 'Adobe XD',
        //reviewCount: '03 reviews',
        title: 'Computer Courses',
        //totalLeson: '18x Lesson',
        //schdule: 'Online Class',
        //authorImgUrl: 'assets/images/course/author/06.jpg',
        //authorImgAlt: 'course author rajibraj91 rajibraj',
        //authorName: 'Angel Mili',
        btnText: 'Read More',
    },
]


const CoursePage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Archives: Courses'} curPage={'Course Page'} />
            <div className="course-section padding-tb section-bg">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="course-showing-part">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                
                               
                            </div>
                        </div>
                        <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
                            {courseList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="course-item">
                                        <div className="course-inner">
                                            <div className="course-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="course-content">
                                                {/* <div className="course-price">{val.price}</div> */}
                                                <div className="course-category">
                                                    <div className="course-cate">
                                                        <NavLink to="#">{val.cate}</NavLink>
                                                    </div>
                                                    <div className="course-reiew">
                                                        <Rating />
                                                        <span className="ratting-count"> {val.reviewCount}</span>
                                                    </div>
                                                </div>
                                                <Link to="#"><h4>{val.title}</h4></Link>
                                                {/* <div className="course-details">
                                                    <div className="couse-count"><i className="icofont-video-alt"></i> {val.totalLeson}</div>
                                                    <div className="couse-topic"><i className="icofont-signal"></i> {val.schdule}</div>
                                                </div> */}
                                                <div className="course-footer">
                                                    {/* <div className="course-author">
                                                        <img src={`${val.authorImgUrl}`} alt={`${val.authorImgAlt}`} />
                                                        <Link to="/team-single" className="ca-name">{val.authorName}</Link>
                                                    </div> */}
                                                    <div className="course-btn">
                                                        <Link to="#" className="lab-btn-text">{val.btnText} <i className="icofont-external-link"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-center mt-5">
                            <a href="assets/images/pdf/TSSR COURSES.pdf" className="lab-btn"><span>{btnText}</span></a>
                        {/* <Link to="assets/images/pdf/TSSR COURSES.pdf/" className="lab-btn"><span>{btnText}</span></Link> */}
                    </div>
                        {/* <Pagination /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default CoursePage;