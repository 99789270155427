
import { Link } from "react-router-dom";

const title = "What We Do";
const subTitle = "Our Services";                   


const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/ss1.jpg',
        imgAlt: 'category ',
        title: 'Franchisee',
        cate: 'We TSSR Council provide franchise to institutions who provide skilled training and other short term courses to maintain quality education.',
    },
    {
        imgUrl: 'assets/images/category/icon/1.jpg',
        imgAlt: 'category ',
        title: 'Certification',
        cate: 'TSSR Council provide iso and other authorities sanctioned certificates to students who complete the course provided by TSSR Council.',
    },
    {
        imgUrl: 'assets/images/category/icon/2.png',
        imgAlt: 'category ',
        title: '1400+ Courses',
        cate: 'TSSR provide about 1400+ courses in various displines such as Technical, Computer, Aviation, Councelling, Paramedical, and many more.',
    },
    {
        imgUrl: 'assets/images/category/icon/3.jpg',
        imgAlt: ' category',
        title: 'Online Verification',
        cate: 'TSSR Council does the online verification of documents. We are trustable and reliable in the field. Therefore, our verification is acceptable world wide.',
    },
   
    {
        imgUrl: 'assets/images/category/icon/ss2.jpg',
        imgAlt: 'category ',
        title: 'Quality Education',
        cate: 'Our all courses are designed in such a way that it provide quality education which capable students to attain jobs. Most of our certified students are working.',
    },
    {
        imgUrl: 'assets/images/category/icon/4.jpg',
        imgAlt: 'category',
        title: 'Accreditations',
        cate: 'Our Courses are accredicted and approved by iso standards and many other goverment agencies. Hence our courses are acceptable world wide all over.',
    },
]


const Category = () => {
    return (
        <div className="course-section  padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i} >
                                <div className="course-item style-5">
                                    <div className="course-inner" style={{backgroundColor:'#ceffe8'}}>
                                        <div className="course-thumb">
                                            <span  className="d-block"><img style={{height:220}} src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></span>
                                        </div>
                                        <div className="course-content">
                                            <h5>{val.title}</h5>
                                            <span className="course-cate">{val.cate}</span>
                                            {/* <div className="course-footer">
                                                <Rating />
                                                <div className="course-price"><del>{val.oldPrice}</del> {val.newPrice}</div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Category;