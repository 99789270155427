import React, { Fragment, useState } from "react";
import { db } from "../firebase";
import Header from "../component/layout/header-tscc";
import Footer from "../component/layout/footer";
import PageHeader from "../component/layout/pageheader";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

//import Achievement from "../component/section/achievement";
//simport Student from "../component/section/student";

//const subTitle = "TSSR COUNCIL";
const title = "Skill Certificate Verification";
const btnText = "Verify";

//onst btnText = "Browse All Categories";

let categoryItemList = [
  // {
  //     imgUrl: 'assets/images/category/icon/15.jpg',
  //     imgAlt: 'category',
  //     title: 'Build Your Brand',
  //     desc: 'Like graphic design, business anaytics coding and much more',
  // },
  // {
  //     imgUrl: 'assets/images/category/icon/16.jpg',
  //     imgAlt: 'category',
  //     title: 'Plan Your Course',
  //     desc: 'Like graphic design, business anaytics coding and much more',
  // },
  {
    imgUrl: "assets/images/category/icon/search.png",
    imgAlt: "category",
    title: "Find Result",
    desc: "Enter Registration Number of the candidate and press Find result button to get result.",
  },
];

var cdata;

const TsccVerfication = () => {
  const [id, setRegId] = useState("");
  const [height, setHeight] = useState(0);

  const clear = (e) => {
    document.getElementById("reg_no").innerHTML = "";
    document.getElementById("skill").innerHTML = "";
    document.getElementById("name").innerHTML = "";
    document.getElementById("skill_test_centre").innerHTML = "";
    document.getElementById("date").innerHTML = "";
  };

  const handleSubmit = async (e) => {
    // e.prevendDefault();

    // onSnapshot(doc(db, "Tsscdata", reg_id), (doc) => {
    //   cdata = doc.data();
    //   //#console.log(cdata);
    // });

    const q = query(collection(db, "TSSC_Data"), where("reg_no", "==", id));
    var snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      setHeight(200);
      snapshot.docs.forEach((doc) => {
        //#console.log(doc.data());
        document.getElementById("skill").innerHTML = doc.data().skill;
        document.getElementById("date").innerHTML = doc.data().exam_date;
        document.getElementById("name").innerHTML = doc.data().name;
        document.getElementById("reg_no").innerHTML = doc.data().reg_no;
        document.getElementById("skill_test_centre").innerHTML =
          doc.data().skill_centre;
      });
    } else {
      setHeight(0);
      clear();
      alert("Invalid or Non-Verified Register Number");
    }
  };

  const style = {
    fontSize: 20,
    fontWeight: "bold",
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"TSCC Online Verification"} curPage={"instructor"} />

      <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="title">{title}</h2>
          </div>

          <form className="account-form">
            <div className="form-group">
              <input
                value={id}
                onChange={(e) => setRegId(e.target.value)}
                type="text"
                name="name"
                placeholder="Certificate Registration No *"
              />
            </div>

            <div className="form-group text-center">
              <input type={"button"} onClick={handleSubmit} value="submit" />
            </div>
          </form>

          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="form-group" style={{ width: 200 }}>
              <label className="admin-page-label">Register Number : </label>
              <p id="reg_no" style={style}></p>
              <label className="admin-page-label">Name : </label>
              <p id="name" style={style}></p>
              <label className="admin-page-label">Skill : </label>
              <p id="skill" style={style}></p>
              <label className="admin-page-label">
                Skill Test Centre :{" "}
              </label>{" "}
              <p id="skill_test_centre" style={style}></p>
              <label className="admin-page-label">Date : </label>{" "}
              <p id="date" style={style}></p>
            </div>
            <img
              src="/assets/images/cursor/verified.jpg"
              style={{ height: height, width: height }}
            />
          </div>
          <div style={{ height: 100 }}></div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default TsccVerfication;
