import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase";
import "./Login.css";
import { useRef } from "react";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  // function verify (){
  //   signInWithEmailAndPasswo rd(auth,email,password)
  //   .then(()=>{
  //       navigate('/Yxr79TOhfiW4ipON6i67KSMeS9D3');
  //   })
  //   .catch((error)=>{
  //       alert(error.message);
  //   })
  // }

  // const loginUser = () => {
  //   signInWithEmailAndPassword(auth, email, password)
  //   .catch((e)=>{alert(e.message.substring(10))})
  // };

  const loginUser = () =>{
    setPersistence(auth,browserSessionPersistence).then(()=>{
      return signInWithEmailAndPassword(auth, email, password)
    }).catch((e)=>{alert(e.message.substring(10))})
  }

  const [ver, setState] = useState(false);
  onAuthStateChanged(auth, (user) => {
    if (user) setState(true)
    // else setState(false);
  },[]);

  if (ver == false)
    return (
      <div className="loginb">
        {/* <div className="admin"><h2>ADMIN PANEL</h2></div> */}

        <div className="login__containerb">
          <div className="admin">
            <h2>ADMIN PANEL</h2>
          </div>
          <input
            type="text"
            className="login__textBoxb"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <input
            type="password"
            className="login__textBoxb"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button className="login__btnb" onClick={() => loginUser()}>
            Login
          </button>

          <button
            className="login__btnb"
            onClick={() => {
              navigate("/");
            }}
          >
            Back To Home
          </button>

          {/* <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div> */}
        </div>
      </div>
    );
  else navigate("/Yxr79TOhfiW4ipON6i67KSMeS9D3");
}
