
import { Link } from "react-router-dom";

const subTitle = "About ";
const title = "TECHNICAL SKILL CERTIFICATION COUNCIL - TSCC";

const instructorList = [
    {
        imgUrl: 'assets/images/instructor/about.jpg',
        imgAlt: 'instructor ',
        cate: 'Based on the recommendations of Technical Study and Skill Research (TSSR) Council, an Autonomous Body & a National Development Authority which promotes TSCC, TSSR Skill Certification Council. We are not depend on any government body, any government bondation with our programmes. TSSR COUNCIL-TSCC is a govt. authorized self-governing organisation.TSCC-TSSR Skill Certification Council is a division of TSSR COUNCIL registered under State Government. The Council has been established in January 2019 with an aim to empower youth to take part in the economic and all round growth of India.',

        // name: 'Kerala',
        // degi: 'Central Administrative Office',
        // courseCount: '08 courses',
        // studentAnroll: '30 students',
    },
    
    
]


const Instructor = () => {
    return (
        <div className="instructor-section padding-tb section-bg" >
            <div className="container" >
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-1">
                        {instructorList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="instructor-item">
                                    <div className="instructor-inner">
                                        <div className="instructor-thumb">
                                            <img  src={`${val.imgUrl}`} alt={`${val.imgAlt}`}/>
                                        </div>
                                        {/* <div className="instructor-content">
                                            <Link to="/team-single"><h4>{val.name}</h4></Link>
                                            <p>{val.degi}</p>
                                            <Rating />
                                        </div> */}
                                    </div>
                                    {/* <div className="instructor-footer">
                                        <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                                            <li><i className="icofont-book-alt"></i> {val.courseCount}</li>
                                            <li><i className="icofont-users-alt-3"></i> {val.studentAnroll}</li>
                                        </ul>
                                    </div> */}
                                    <div className="course-content">
                                            <h5>{val.title}</h5>
                                            <span className="course-cate">{val.cate}</span>
                                            </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-center footer-btn">

                        <p>Want to know more about TSCC<Link to="/tssc-policy">Click here</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Instructor;