import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Popup from "reactjs-popup";


const phoneNumber = "+91 9400867461";
const address = "Puthupadi, Calicut, Kerala, India";

let socialList = [
  {
    iconName: "icofont-facebook-messenger",
    siteLink: "#",
  },
  {
    iconName: "icofont-twitter",
    siteLink: "#",
  },
  {
    iconName: "icofont-vimeo",
    siteLink: "#",
  },
  {
    iconName: "icofont-skype",
    siteLink: "#",
  },
  {
    iconName: "icofont-rss-feed",
    siteLink: "#",
  },
];

const TsccHeader = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li>
                <p>Find us on : </p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <NavLink to={val.siteLink}>
                    <i className={val.iconName}></i>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/tscc-home">
                <img src="assets/images/tsscp/logo2.png" alt="logo" />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
              <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                <li className="menu-item-has-children">
               
                    <a
                      style={{color:'grey'}}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Home
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <a href="/">TSSR</a>
                        
                      </li>
                      <li>
                        <a href="/#/tscc-home">TSCC</a>
                        
                      </li>
                      </ul></li>
                         


               
                  <li className="menu-item-has-children">
                    <a 
                      style={{color:'grey'}}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Services
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      {/* <li>
                        <NavLink to="/franchise">Franchise</NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/tscc-accreditation">Accreditations</NavLink>
                      </li>
                      <li>
                        <NavLink to="/tscc-verfication">Online Verfication</NavLink>
                      </li>

                      <li>
                        <NavLink to="/atc-verification">Authorised Study Centres</NavLink>
                      </li>

                      {/* <li>
                        <NavLink to="/coming_soon">Careers</NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/tscc-downloads">Downloads</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/blog-single">Messages</NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/coming_soon">Admission</NavLink>
                      </li>
                      <li>
                        <a href="/assets/images/pdf/FAQs.pdf">FAQs</a>
                      </li>
                      {/* <li>
                        <NavLink to="/assets/images/pdf/mark list.pdf">Model Marklist</NavLink>
                      </li>
                      <li>
                        <NavLink to="/assets/images/pdf/certificate.pdf">Model Certificate</NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/gallery">Gallery</NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="menu-item-has-children">
                    <a
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Examination
                    </NavLink>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/about">Online Exam</NavLink>
                      </li>
                      <li>
                        <NavLink to="/assets/images/pdf/hall ticket.pdf">Hall Ticket</NavLink>
                      </li>
                      <li>
                        <NavLink to="/instructor">Check Result</NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/shop">Shop Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/shop-single">Shop Details Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/cart-page">Shop Cart Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/search-page">Search Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/search-none">Search None</NavLink>
                      </li>
                      <li>
                        <NavLink to="/404">404</NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/coming_soon">Coming Soon</NavLink>
                      </li> */}
                    {/* </ul>
                  </li> */}
                 
                  <li>
                    <NavLink to="/contact_us" style={{color:'grey'}}>Contact Us</NavLink>
                  </li>
                </ul>
              </div>

              <GetAdmission/>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {/* <Link to="/login" className="signup">
                <i className="icofont-users"></i> <span>Login</span>{" "}
              </Link> */}

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TsccHeader;




const TSSR_link = "/online-verification";
const TSSC_link = "/tscc-verfication";

const buttonStyle = {
  marginTop: 20,
  marginLeft: 20,
  marginRight: 20,
  padding: 30,
  borderRadius: 50,
  backgroundColor: "#0f317c",
  color: "#ffffff",
  fontSize: 30,
  fontWeight: "bolder",
};

const click_here_style={
  fontSize:15,
  fontWeight:'bold'
}


const GetAdmission = () => {
  return (
    <Popup
      trigger={
        <Link to="#" className="login">
                <i className="icofont-user"></i> <span>Verify</span>{" "}
              </Link>

      }
      modal
      position="top center"
    >
      {close => (<center>
        <div
          style={{
            backgroundColor: "#8396C3",
            borderRadius: 50,
            borderColor: "#0f317c",
            borderWidth: 5,
            borderStyle: "solid",
            padding: 20,
          }}
        >
          <div className="close-button" onClick={()=>{close();}}><center><NavLink to={{}}>X</NavLink></center></div>
          {/* <h2 style={{color:'#26c976'}}>Best education franchise provider<br/>and Certification Body</h2> */}
          <NavLink to={TSSR_link}>
            <img
              src="assets/images/popup_photos/logo.png"
              style={buttonStyle}
            />
            <br />
            <span style={click_here_style}>click here for TSSR Verification▲</span>
          </NavLink>
          <NavLink to={TSSC_link}>
            <img
              src="assets/images/popup_photos/logo2.png"
              style={buttonStyle}
            />
            <br />
            <span style={click_here_style}>click here for TSCC Verification▲</span>
          </NavLink>

        </div>
      </center>)}
    </Popup>
  );
};
